import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  getContestPublicVotingRequest,
  getContestRequest,
  getContestWinnersRequest
} from 'src/redux/actions/contests';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import {
  Button,
  MenuItem,
  Select
} from '@mui/material';
import UserProfile from 'src/components/UserProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareCheck,
  faSquare,
  faSquareMinus
} from '@fortawesome/free-regular-svg-icons';

const ContestConfirmWinners = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const fetchingWinners = useSelector(state => state.loadingStore.GET_CONTEST_WINNERS);
  const fetchingPublicVotes = useSelector(state => state.loadingStore.GET_CONTEST_PUBLIC_VOTES);
  const [scoresheet, setScoresheet] = useState('');
  const [scoresheets, setScoresheets] = useState();
  const [scoresheetName, setScoresheetName] = useState();
  const [currentCategory, setCurrentCategory] = useState();
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const publicVoting = {
    name: 'Public Voting',
    scoresheetID: 'public-voting'
  };

  if (!contestURL) {
    navigate('/');
  }

  const handleScoresheetChange = ({ value }) => {
    if (value !== publicVoting.scoresheetID) {
      dispatch(getContestWinnersRequest({ clientURL: client.url, contestURL: contest.url, scoresheetID: value }));
    } else {
      dispatch(getContestPublicVotingRequest({ clientURL: client.url, contestURL: contest.url }));
    }
    setScoresheet(value);
    const newScoresheet = scoresheets.find(scoresheet => scoresheet.scoresheetID === value);
    setScoresheetName(newScoresheet.name);
    setCurrentCategory();
  }

  const handleSelectCategory = (category)  => {
    setCurrentCategory(category);
  }

  const categoryHasNoEntries = (item) => {
    return !item?.entries?.length;
  }

  const actions = [{
    color: 'confirm',
    label: 'Assign Awards',
    requiresSelection: true,
    variant: 'contained',
    fn: entry => {
      dispatch(
        openModal({
          props: { client, contest, category: currentCategory, entry },
          key: 'assignContestAwards'
        })
      )
    }
  }];

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    if (contest?.url && !scoresheets) {
      const combinedScoresheets = contest.enablePublicVoting ? [...(contest.scoresheets || []), publicVoting] : contest.scoresheets;
      setScoresheets(combinedScoresheets);
      if (combinedScoresheets.length > 0) {
        dispatch(getContestWinnersRequest({ clientURL: client.url, contestURL: contest.url, scoresheetID: combinedScoresheets[0].scoresheetID }));
        setScoresheet(combinedScoresheets[0].scoresheetID);
        setScoresheetName(combinedScoresheets[0].name);
      }
    }
  }, [contest?.url]);

  useEffect(() => {
    if (currentCategory) {
      const newCategory = contest?.winnersCategoryList.find(category => category.categoryID === currentCategory.categoryID);
      setCurrentCategory(newCategory);
    }
  }, [contest?.winnersCategoryList]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestConfirmWinners', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestConfirmWinners',
      options: { client, contest }
    }));
  }, [client, contest]);

  const renderOptionsContent = () => {
    if (contestFetching) {
      return '';
    }

    if (!scoresheets?.length) {
      return <div className="box-content options">No Scoresheets Found, Cannot Assign Winners</div>;
    }

    return <>
      <div className="box-content options">
        <div>
          <label>Viewing Scores For:</label>
          <Select 
            onChange={event => handleScoresheetChange({ value: event.target.value })}
            value={scoresheet}
          >{
            (scoresheets || [])?.map(scoresheet => {
              return (
                <MenuItem
                  key={scoresheet.scoresheetID}
                  value={scoresheet.scoresheetID}
                >{scoresheet.name}</MenuItem>
              )
            })
          }</Select>
        </div>
        <div className="selected-items">
          {
            currentCategory && (
              <Button
                onClick={() => {
                  setCurrentCategory();
                }}
                size="small"
                variant="contained"
              >Back To Category List</Button>
            )
          }
        </div>
      </div>
      {
        (!fetchingWinners && currentCategory?.judges?.length > 0) && (
          <div className="box-content judges">
            <p>Contributing Judges: </p>
            <div className="users">
              {
                (currentCategory?.judges || [])?.map(judge => 
                  <UserProfile
                    key={judge.userID}
                    showEmail
                    user={judge}
                  />
                )
              }
            </div>
          </div>
        )
      }
    </>
  }

  const renderPrimaryContent = () => {
    if (contestFetching || fetchingWinners || fetchingPublicVotes || !scoresheets?.length) {
      return '';
    }

    return !currentCategory ?
      <ResourceList
        itemDisabled={categoryHasNoEntries}
        list={scoresheet === publicVoting.scoresheetID ? (contest?.publicVotes?.categories.filter(category => !category.hidePublicVoting)) || [] : contest?.winnersCategoryList || []}
        notSelectable
        onClickFn={handleSelectCategory}
        renderItem={category => {
          const hasAwards = category.entries.some(entry => {
            return entry.awards.some(award => award.selected);
          });
          const noEntries = categoryHasNoEntries(category);
          return (
            <div className="category">
              {
                noEntries && (
                  <FontAwesomeIcon icon={faSquareMinus} />
                )
              }
              {
                hasAwards && (
                  <FontAwesomeIcon icon={faSquareCheck} />
                )
              }
              {
                (!hasAwards &&!noEntries) && (
                  <FontAwesomeIcon icon={faSquare} />
                )
              }
              <div className="category-name">{category.label}: {category.description}</div>
            </div>
          )
        }}
        renderKey="categoryID"
        title={`Category List For ${scoresheetName}`}
      /> : <ResourceList
      actions={actions}
      list={currentCategory?.entries || []}
      renderItem={entry => 
        <div className="entry">
          <div className="entry-row">
            <div className="entry-number">
              {currentCategory.label}-{entry.number} <a
                href={`https://${client.url}.cpjam.com/${contest.url}/entries/${entry.entryID}`}
                rel="noreferrer"
                target="_blank"
              >
                <Button
                  size="small"
                  variant="contained"
                >View</Button>
              </a>
            </div>
            <div className="entry-score">
              {
                scoresheet === publicVoting.scoresheetID ?
                <span>Total Votes: {entry.votes}</span> :
                <span>Score: {entry.average}</span>
              }
            </div>
          </div>
          <div className="entry-row">
            <div className="entry-contributors">
              <span>Owner:</span>
              <span className="users">
                {
                  <UserProfile
                    showEmail
                    user={entry.owner}
                  />
                }
              </span>
            </div>
            <div className="entry-awards">
              <span>Awards: </span>
              <span className="awards">
                {
                  (entry?.awards || [])?.filter(award => {
                    return award.selected;
                  }).map(award => 
                    <span key={award.awardID}>{award.name}</span>
                  ).reduce((acc, item) => acc === null ? [item] : [...acc, ', ', item], null) || '[None]'
                }
              </span>
            </div>
          </div>
        </div>
      }
      renderKey="entryID"
      title={`Entry List For ${currentCategory?.label}: ${currentCategory?.description}`}
    />
  }

  return (
    <section className="confirm-winners grid">
      <section className="box">
        <div className="box-title">
          <h3>
            {
              contestFetching ?
                <LoadingCircle variant="dark" /> :
                <span>Confirm Winners Options
                {
                  (fetchingWinners || fetchingPublicVotes) && (
                    <LoadingCircle variant="dark" />
                  )
                }
                </span>
            }
          </h3>
        </div>
        { renderOptionsContent() }
      </section>
      { renderPrimaryContent() }
    </section>
  )
}

export default ContestConfirmWinners;
