import axios from 'axios';
import { cpj_auth_key } from 'src/constants';
import { getCookie } from 'src/utilities/cookies';

const authKey = () => {
  return {
    Authorization: `Bearer ${getCookie({ key: cpj_auth_key }) || ''}`
  }
}

const baseHeaders = () => {
  return {
    headers: {
      ...authKey(),
      'Content-Type': 'application/json'
    }
  };
}

const getRequestOptions = () => {
  return {
    ...baseHeaders(),
    method: 'GET'
  };
}

const postRequestOptions = () => {
  return {
    ...baseHeaders(),
    method: 'POST'
  };
}

const fileRequestOptions = () => {
  return {
    headers: {
      ...authKey(),
      'Content-Type': 'multipart/form-data'
    },
    method: 'POST'
  };
}

const createRequestOptions = ({ download, form, handleProgress }) => {
  if (form?.file && handleProgress) {
    return {
      ...fileRequestOptions(),
      data: form.file,
      onUploadProgress: progressEvent => {
        handleProgress(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)))
      }
    };
  }

  if (form) {
    return {
      ...postRequestOptions(),
      data: JSON.stringify(form)
    };
  }

  if (download) {
    return { ...getRequestOptions(), responseType: 'arraybuffer' };
  }

  return { ...getRequestOptions() };
}

const handleFetch = async ({ download, form, handleProgress, url }) => new Promise(async (resolve, reject) => {
  try {
    const requestOptions = createRequestOptions({ download, form, handleProgress });

    const response = await axios({
      ...requestOptions,
      url: `${process.env.REACT_APP_API_DOMAIN}${url}`
    });

    if (response.status === 200) {
      if (download) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', download);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        resolve();
      }

      return resolve(response.data);
    }

    reject({ message: 'General server error', status: response.status });
  } catch (error) {
    reject({ message: error.response?.data?.error, status: error.response?.status })
  }
});

export default handleFetch;
