import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { getContestRequest } from 'src/redux/actions/contests';
import { fieldTypes, inputTypes, productTypes } from 'src/constants';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import AutoForm from 'src/components/AutoForm';
import { Button } from '@mui/material';
import { parseDateTime } from 'src/utilities/dates';

const ContestPreview = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const [categories, setCategories] = useState();
  const [scholarships, setScholarships] = useState();
  const [entries, setEntries] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedScholarship, setSelectedScholarship] = useState();
  const [selectedEntry, setSelectedEntry] = useState();
  const [applicationScoringPageConfiguration, setApplicationScoringPageConfiguration] = useState();
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestPreview', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestPreview',
      options: { client, contest } 
    }));

    if (contest?.type === productTypes.contest) {
      setCategories((contest?.categories || []).map(category => {
        return {
          label: `${category.label}: ${category.description}`,
          value: category.categoryID
        };
      }));

      setEntries((contest?.entries || []).map(entry => {
        return {
          label: `${entry.label}-${entry.number}`,
          value: entry.entryID
        };
      }))

      setSelectedCategory((contest?.categories || [])[0]?.categoryID);
      setSelectedEntry((contest?.entries || [])[0]?.entryID);
    }

    if (contest?.type === productTypes.scholarship) {
      const scholarships = (contest?.scholarships || []).map(scholarship => {
        return {
          label: scholarship.name,
          url: scholarship.url,
          value: scholarship.scholarshipID
        };
      })
      setScholarships(scholarships);
      setApplicationScoringPageConfiguration(getApplicationScoringPageConfiguration({ index: 0, scholarships }));
    }
  }, [client, contest]);

  useEffect(() => {
    if (selectedScholarship) {
      const index = contest?.scholarships.findIndex(scholarship => scholarship.scholarshipID === selectedScholarship);
      setEntries((contest?.scholarships[index]?.applications || []).map(application => {
        return {
          label: application.fullName,
          value: application.entryID
        };
      }));
      setSelectedEntry((contest?.scholarships[index]?.applications || [])[0]?.entryID);
    }
  }, [selectedScholarship]);

  const onChange = ({ element, value }) => {
    if (element?.name === 'scholarship') {
      const index = (contest?.scholarships || []).findIndex(scholarship => scholarship.scholarshipID === value);
      if (index > -1) {
        setApplicationScoringPageConfiguration(getApplicationScoringPageConfiguration({ index, scholarships }));
      }
    }
  }

  const handleRegistrationPreview = (event) => {
    const dateTime = encodeURIComponent(parseDateTime({ date: event.previewDate }));
    window.open(`https://${client.url}.cpjam.com/${contest?.url}/preview/registration/${dateTime}`, '_blank');
  }

  const handleEntryPagePreview = (event) => {
    window.open(`https://${client.url}.cpjam.com/${contest?.url}/preview/entries/${event.category}`, '_blank');
  }

  const handleScoringPagePreview = (event) => {
    window.open(`https://${client.url}.cpjam.com/${contest?.url}/preview/scoring/${event.entry}`, '_blank');
  }

  const handleGalleryPreview = () => {
    window.open(`https://${client.url}.cpjam.com/${contest?.url}/preview/gallery`, '_blank');
  }

  const handleApplicationPagePreview = (event) => {
    const scholarship = scholarships.find(scholarship => event.scholarship === scholarship.value);
    window.open(`https://${client.url}.cpjam.com/${contest?.url}/preview/scholarships/${scholarship.url}/applications`, '_blank');
  }

  const handleApplicationScoringPagePreview = (event) => {
    const scholarship = scholarships.find(scholarship => event.scholarship === scholarship.value);
    window.open(`https://${client.url}.cpjam.com/${contest?.url}/preview/scholarships/${scholarship.url}/scoring/${event.entry}`, '_blank');
  }

  const registrationConfiguration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Registation Date/Time',
          name: 'previewDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: '',
          required: true
        }]
      }]
    },
    submitCTA: 'Preview'
  }

  const entryPageConfiguration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Preview Entry Management For Category',
          name: 'category',
          type: fieldTypes.select,
          value: selectedCategory,
          options: categories
        }]
      }]
    },
    submitCTA: 'Preview'
  }

  const applicationPageConfiguration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Preview Application Submission For Scholarship',
          name: 'scholarship',
          type: fieldTypes.select,
          value: (contest?.scholarships || [])[0]?.scholarshipID || '',
          options: scholarships
        }]
      }]
    },
    submitCTA: 'Preview'
  }

  const scoringPageConfiguration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Preview Scoring For Submission',
          name: 'entry',
          type: fieldTypes.select,
          value: selectedEntry,
          options: entries
        }]
      }]
    },
    submitCTA: 'Preview'
  }

  const getApplicationScoringPageConfiguration = ({ index, scholarships }) => {
    const selectedScholarship = contest?.scholarships[index].scholarshipID;
    setSelectedScholarship(selectedScholarship);
    const entries = ((contest?.scholarships[index]?.applications || []).map(application => {
      return {
        label: application.fullName,
        value: application.entryID
      };
    }));
    const selectedEntry = (contest?.scholarships[index]?.applications || [])[0]?.entryID;

    return {
      formElements: {
        rows: [{
          elements: [{
            label: 'Preview Application Submission For Scholarship',
            name: 'scholarship',
            type: fieldTypes.select,
            value: selectedScholarship,
            options: scholarships
          }]
        }, {
          elements: [{
            label: 'Preview Scoring For Submission',
            name: 'entry',
            type: fieldTypes.select,
            value: selectedEntry,
            options: entries
          }]
        }]
      },
      submitDisabled: !(entries || []).length,
      submitCTA: 'Preview'
    }
  }

  if (contest?.type === productTypes.scholarship) {
    return (
      <section className="contest-previews grid">
        {
          (!contestFetching && scholarships?.length > 0) && (
            <section className="box">
              <div className="box-title">
                <h3><span>Preview Application Page</span></h3>
              </div>
              <div className="box-content">
                <AutoForm
                  configuration={applicationPageConfiguration}
                  handleSubmit={handleApplicationPagePreview}
                  requestPrefix=""
                />
              </div>
            </section>
          )
        }
        {
          (!contestFetching && scholarships?.length > 0) && (
            <section className="box">
              <div className="box-title">
                <h3><span>Preview Scoring Page</span></h3>
              </div>
              <div className="box-content">
                <AutoForm
                  configuration={applicationScoringPageConfiguration}
                  handleSubmit={handleApplicationScoringPagePreview}
                  onChange={onChange}
                  requestPrefix=""
                />
              </div>
            </section>
          )
        }
      </section>
    )
  }

  if (contest?.type === productTypes.contest) {
    return (
      <section className="contest-previews grid">
        <section className="box">
          <div className="box-title">
            <h3>
              {
                contestFetching ?
                  <LoadingCircle variant="dark" /> :
                  <span>Preview Registration Page</span>
              }
            </h3>
          </div>
          {
            !contestFetching && (
            <div className="box-content">
              <AutoForm
                configuration={registrationConfiguration}
                handleSubmit={handleRegistrationPreview}
                requestPrefix=""
              />
            </div>
            )
          }
        </section>
        {
          (!contestFetching && categories?.length > 0) && (
            <section className="box">
              <div className="box-title">
                <h3><span>Preview Entry Page</span></h3>
              </div>
              <div className="box-content">
                <AutoForm
                  configuration={entryPageConfiguration}
                  handleSubmit={handleEntryPagePreview}
                  requestPrefix=""
                />
              </div>
            </section>
          )
        }
        {
          (!contestFetching && entries?.length > 0) && (
            <section className="box">
              <div className="box-title">
                <h3><span>Preview Scoring Page</span></h3>
              </div>
              <div className="box-content">
                <AutoForm
                  configuration={scoringPageConfiguration}
                  handleSubmit={handleScoringPagePreview}
                  requestPrefix=""
                />
              </div>
            </section>
          )
        }
        {
          !contestFetching && (
            <section className="box">
              <div className="box-title">
                <h3><span>Preview Winners Gallery</span></h3>
              </div>
              <div className="box-content contest-previews-button">
                <Button
                  onClick={handleGalleryPreview}
                  variant="contained"
                >Preview</Button>
              </div>
            </section>
          )
        }
      </section>
    )
  }
}

export default ContestPreview;
