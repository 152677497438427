import { call, put, takeEvery } from 'redux-saga/effects';
import handleFetch from 'src/utilities/fetch';
import * as actions from 'src/redux/actions/contests';
import * as userActions from 'src/redux/actions/users';
import { toast } from 'react-toastify';

async function archiveContest({ clientURL, contestURL }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/archive`, form: {} });
}

async function assignContestAwards({ clientURL, contestURL, entryID, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/awards/${entryID}/assign`, form });
}

async function convertContestJudgeProspect({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/judge-prospects/convert`, form });
}

async function createContest({ clientURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests`, form });
}

async function createContestArchive({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/archives`, form });
}

async function downloadContestEntryForm({ clientURL, contestURL, entryFormID, onlyWinners }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/download-entry-form/${entryFormID}${onlyWinners ? '?onlyWinners=true' : ''}`,
    download: `contest_entry_form_${entryFormID}.xlsx`
  });
}

async function downloadContestRegistrations({ clientURL, contestURL, contestID }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/download-registrations`,
    download: `contest_registrations_${contestID}.xlsx`
  });
}

async function downloadContestScores({ clientURL, contestURL, scoresheetID, onlyWinners }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/download-scores/${scoresheetID}${onlyWinners ? '?onlyWinners=true' : ''}`,
    download: `contest_scores_${scoresheetID}.xlsx`
  });
}

async function downloadContestWinners({ clientURL, contestURL, contestID, onlyWinners }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/download-winners${onlyWinners ? '?onlyWinners=true' : ''}`,
    download: `contest_entry_list_${contestID}.xlsx`
  });
}

async function duplicateApplicationForm({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/application-forms/duplicate`, form });
}

async function duplicateContest({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/duplicate`, form });
}

async function duplicateEntryForm({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/entry-forms/duplicate`, form });
}

async function duplicateScoresheet({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/scoresheets/duplicate`, form });
}

async function getContest({ clientURL, contestURL }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/edit` });
}

async function getContestArchives({ clientURL, contestURL }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/archives` });
}

async function getContestList({ clientURL }) {
  return await handleFetch({ url: `/${clientURL}/contests/edit` });
}

async function getContestPublicVotes({ clientURL, contestURL }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/public-votes` });
}

async function getContestWinners({ clientURL, contestURL, scoresheetID }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/winners/scoresheet/${scoresheetID}` });
}

async function getScoringProgressByEntry({ clientURL, contestURL }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/progress-by-entry` });
}

async function getScoringProgressByJudge({ clientURL, contestURL }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/progress-by-judge` });
}

async function getScoringProgressForEntry({ clientURL, contestURL, entryID, userID }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/progress/${userID}/${entryID}` });
}

async function getScoringProgressEntriesForJudge({ clientURL, contestURL, userID }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/entries-for-judge/${userID}` });
}

async function getScoringProgressJudgesForEntry({ clientURL, contestURL, entryID }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/judges-for-entry/${entryID}` });
}

async function removeContestApplicationFolder({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/application-folders/remove`, form });
}

async function removeContestApplicationForm({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/application-forms/remove`, form });
}

async function removeContestArchive({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/archives/remove`, form });
}

async function removeContestAward({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/awards/remove`, form });
}

async function removeContestBannerImage({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/banners/remove`, form });
}

async function removeContestCategory({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/categories/remove`, form });
}

async function removeContestCoordinator({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/coordinators/remove`, form });
}

async function removeContestDocumentFolder({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/document-folders/remove`, form });
}

async function removeContestDownloadableFile({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/files/remove`, form });
}

async function removeContestEntryForm({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/entry-forms/remove`, form });
}

async function removeContestJudge({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/judge-assignments/remove`, form });
}

async function removeContestJudgeInvitation({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/judge-assignments/invitation/remove`, form });
}

async function removeContestJudgeProspect({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/judge-prospects/remove`, form });
}

async function removeContestOptionalFee({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/fees/remove`, form });
}

async function removeContestPromoCode({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/promo-codes/remove`, form });
}

async function removeContestRegistration({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/transactions/remove`, form });
}

async function removeContestScholarship({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/scholarships/remove`, form });
}

async function removeContestScoresheet({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/scoresheets/remove`, form });
}

async function removeContestSponsorTier({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/sponsors/tiers/remove`, form });
}

async function removeContestSupportingProductTimeframe({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/supporting-product-timeframes/remove`, form });
}

async function removeContestTimeframe({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/timeframes/remove`, form });
}

async function reorderContestApplicationFolders({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/application-folders/order`, form });
}

async function reorderContestApplicationForms({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/application-forms/order`, form });
}

async function reorderContestAwards({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/awards/order`, form });
}

async function reorderContestCategories({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/categories/order`, form });
}

async function reorderContestCoordinators({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/coordinators/order`, form });
}

async function reorderContestDocumentFolders({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/document-folders/order`, form });
}

async function reorderContestEntryForms({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/entry-forms/order`, form });
}

async function reorderContestOptionalFees({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/fees/order`, form });
}

async function reorderContestScholarships({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/scholarships/order`, form });
}

async function reorderContestScoresheets({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/scoresheets/order`, form });
}

async function reorderContestSponsorTiers({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/sponsors/tiers/order`, form });
}

async function requestContestJudgeVolunteer({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/judge-prospects`, form });
}

async function requestContestJudgeVolunteerList({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/volunteers`, form });
}

async function selectContestBannerImage({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/banners/select`, form });
}

async function sendContestNotifications({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/notifications`, form });
}

async function updateContestApplicationFolder({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/application-folders`, form });
}

async function updateContestApplicationForm({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/application-forms`, form });
}

async function updateContestApplicationFormStructure({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/application-forms/structure`, form });
}

async function updateContestAward({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/awards`, form });
}

async function updateContestCategory({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/categories`, form });
}

async function updateContestConfiguration({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/options`, form });
}

async function updateContestConfirmationEmail({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/confirmation-email`, form });
}

async function updateContestConnectedEvent({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/event`, form });
}

async function updateContestCoordinator({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/coordinators`, form });
}

async function updateContestCustomPageSettings({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/custom-pages`, form });
}

async function updateContestCustomPageText({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/custom-pages/text`, form });
}

async function updateContestDates({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/dates`, form });
}

async function updateContestDocumentFolder({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/document-folders`, form });
}

async function updateContestDownloadableFile({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/files`, form });
}

async function updateContestEntryForm({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/entry-forms`, form });
}

async function updateContestEntryFormStructure({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/entry-forms/structure`, form });
}

async function updateContestGallery({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/gallery`, form });
}

async function updateContestInstructions({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/instructions`, form });
}

async function updateContestJudge({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/judge-assignments`, form });
}

async function updateContestJudgeInvitation({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/judge-assignments/invitation`, form });
}

async function updateContestJudgeProspect({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/judge-prospects`, form });
}

async function updateContestName({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/info`, form });
}

async function updateContestOptionalFee({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/fees`, form });
}

async function updateContestPartnerPage({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/partner-page`, form });
}

async function updateContestPromoCode({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/promo-codes`, form });
}

async function updateContestPublicVoting({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/public-voting`, form });
}

async function updateContestRegistration({ clientURL, contestURL, transactionID, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/transactions/${transactionID}`, form });
}

async function updateContestRules({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/registration-page`, form });
}

async function updateContestScholarship({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/scholarships`, form });
}

async function updateContestScholarshipRequirements({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/scholarships/requirements`, form });
}

async function updateContestScoresheet({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/scoresheets`, form });
}

async function updateContestScoresheetCriteria({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/scoresheets/criteria`, form });
}

async function updateContestScoringInstructions({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/scoring-instructions`, form });
}

async function updateContestSEO({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/seo`, form });
}

async function updateContestSponsorTier({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/sponsors/tiers`, form });
}

async function updateContestSponsorOptions({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/sponsor-options`, form });
}

async function updateContestSupportingProductTimeframe({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/supporting-product-timeframes`, form });
}

async function updateContestTimeframe({ clientURL, contestURL, form }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/timeframes`, form });
}

async function uploadContestBannerImage({ clientURL, file, handleProgress }) {
  return await handleFetch({ url: `/clients/${clientURL}/banners`, form: { file }, handleProgress });
}

async function uploadContestDownloadableFile({ clientURL, contestURL, file, handleProgress }) {
  return await handleFetch({ url: `/${clientURL}/contests/${contestURL}/files`, form: { file }, handleProgress });
}

function* archiveContestRequest(request) {
  try {
    const context = yield call(() => archiveContest(request.body));
    yield put({ type: actions.ARCHIVE_CONTEST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.ARCHIVE_CONTEST_FAILED, error });
  }
}

function* assignContestAwardsRequest(request) {
  try {
    const context = yield call(() => assignContestAwards(request.body));
    yield put({ type: actions.ASSIGN_CONTEST_AWARDS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.ASSIGN_CONTEST_AWARDS_FAILED, error });
  }
}

function* clearContestVolunteersRequest() {
  yield put({ type: actions.CLEAR_CONTEST_VOLUNTEERS_SUCCESS });
}

function* clearScoringProgressEntriesRequest() {
  yield put({ type: actions.CLEAR_SCORING_PROGRESS_ENTRIES_SUCCESS });
}

function* clearScoringProgressJudgesRequest() {
  yield put({ type: actions.CLEAR_SCORING_PROGRESS_JUDGES_SUCCESS });
}

function* convertContestJudgeProspectRequest(request) {
  try {
    const context = yield call(() => convertContestJudgeProspect(request.body));
    yield put({ type: actions.CONVERT_CONTEST_JUDGE_PROSPECT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CONVERT_CONTEST_JUDGE_PROSPECT_FAILED, error });
  }
}

function* createContestRequest(request) {
  try {
    const context = yield call(() => createContest(request.body));
    yield put({ type: actions.CREATE_CONTEST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CREATE_CONTEST_FAILED, error });
  }
}

function* createContestArchiveRequest(request) {
  try {
    const context = yield call(() => createContestArchive(request.body));
    yield put({ type: actions.CREATE_CONTEST_ARCHIVE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CREATE_CONTEST_ARCHIVE_FAILED, error });
  }
}

function* downloadContestEntryFormRequest(request) {
  try {
    const context = yield call(() => downloadContestEntryForm(request.body));
    yield put({ type: actions.DOWNLOAD_CONTEST_ENTRY_FORM_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DOWNLOAD_CONTEST_ENTRY_FORM_FAILED, error });
  }
}

function* downloadContestRegistrationsRequest(request) {
  try {
    const context = yield call(() => downloadContestRegistrations(request.body));
    yield put({ type: actions.DOWNLOAD_CONTEST_REGISTRATIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DOWNLOAD_CONTEST_REGISTRATIONS_FAILED, error });
  }
}

function* downloadContestScoresRequest(request) {
  try {
    const context = yield call(() => downloadContestScores(request.body));
    yield put({ type: actions.DOWNLOAD_CONTEST_SCORES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DOWNLOAD_CONTEST_SCORES_FAILED, error });
  }
}

function* downloadContestWinnersRequest(request) {
  try {
    const context = yield call(() => downloadContestWinners(request.body));
    yield put({ type: actions.DOWNLOAD_CONTEST_WINNERS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DOWNLOAD_CONTEST_WINNERS_FAILED, error });
  }
}

function* duplicateApplicationFormRequest(request) {
  try {
    const context = yield call(() => duplicateApplicationForm(request.body));
    yield put({ type: actions.DUPLICATE_APPLICATION_FORM_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DUPLICATE_APPLICATION_FORM_FAILED, error });
  }
}

function* duplicateContestRequest(request) {
  try {
    const context = yield call(() => duplicateContest(request.body));
    yield put({ type: actions.DUPLICATE_CONTEST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DUPLICATE_CONTEST_FAILED, error });
  }
}

function* duplicateEntryFormRequest(request) {
  try {
    const context = yield call(() => duplicateEntryForm(request.body));
    yield put({ type: actions.DUPLICATE_ENTRY_FORM_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DUPLICATE_ENTRY_FORM_FAILED, error });
  }
}

function* duplicateScoresheetRequest(request) {
  try {
    const context = yield call(() => duplicateScoresheet(request.body));
    yield put({ type: actions.DUPLICATE_SCORESHEET_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DUPLICATE_SCORESHEET_FAILED, error });
  }
}

function* getContestRequest(request) {
  try {
    const context = yield call(() => getContest(request.body));
    yield put({ type: actions.GET_CONTEST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_CONTEST_FAILED, error });
  }
}

function* getContestArchivesRequest(request) {
  try {
    const context = yield call(() => getContestArchives(request.body));
    yield put({ type: actions.GET_CONTEST_ARCHIVES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_CONTEST_ARCHIVES_FAILED, error });
  }
}

function* getContestListRequest(request) {
  try {
    const context = yield call(() => getContestList(request.body));
    yield put({ type: actions.GET_CONTEST_LIST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_CONTEST_LIST_FAILED, error });
  }
}

function* getContestPublicVotesRequest(request) {
  try {
    const context = yield call(() => getContestPublicVotes(request.body));
    yield put({ type: actions.GET_CONTEST_PUBLIC_VOTES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_CONTEST_PUBLIC_VOTES_FAILED, error });
  }
}

function* getContestWinnersRequest(request) {
  try {
    const context = yield call(() => getContestWinners(request.body));
    yield put({ type: actions.GET_CONTEST_WINNERS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_CONTEST_WINNERS_FAILED, error });
  }
}

function* getScoringProgressByEntryRequest(request) {
  try {
    const context = yield call(() => getScoringProgressByEntry(request.body));
    yield put({ type: actions.GET_SCORING_PROGRESS_BY_ENTRY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_SCORING_PROGRESS_BY_ENTRY_FAILED, error });
  }
}

function* getScoringProgressByJudgeRequest(request) {
  try {
    const context = yield call(() => getScoringProgressByJudge(request.body));
    yield put({ type: actions.GET_SCORING_PROGRESS_BY_JUDGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_SCORING_PROGRESS_BY_JUDGE_FAILED, error });
  }
}

function* getScoringProgressForEntryRequest(request) {
  try {
    const context = yield call(() => getScoringProgressForEntry(request.body));
    yield put({ type: actions.GET_SCORING_PROGRESS_FOR_ENTRY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_SCORING_PROGRESS_FOR_ENTRY_FAILED, error });
  }
}

function* getScoringProgressEntriesForJudgeRequest(request) {
  try {
    const context = yield call(() => getScoringProgressEntriesForJudge(request.body));
    yield put({ type: actions.GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_FAILED, error });
  }
}

function* getScoringProgressJudgesForEntryRequest(request) {
  try {
    const context = yield call(() => getScoringProgressJudgesForEntry(request.body));
    yield put({ type: actions.GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_FAILED, error });
  }
}

function* removeContestApplicationFolderRequest(request) {
  try {
    const context = yield call(() => removeContestApplicationFolder(request.body));
    yield put({ type: actions.REMOVE_CONTEST_APPLICATION_FOLDER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_APPLICATION_FOLDER_FAILED, error });
  }
}

function* removeContestApplicationFormRequest(request) {
  try {
    const context = yield call(() => removeContestApplicationForm(request.body));
    yield put({ type: actions.REMOVE_CONTEST_APPLICATION_FORM_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_APPLICATION_FORM_FAILED, error });
  }
}

function* removeContestArchiveRequest(request) {
  try {
    const context = yield call(() => removeContestArchive(request.body));
    yield put({ type: actions.REMOVE_CONTEST_ARCHIVE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_ARCHIVE_FAILED, error });
  }
}

function* removeContestAwardRequest(request) {
  try {
    const context = yield call(() => removeContestAward(request.body));
    yield put({ type: actions.REMOVE_CONTEST_AWARD_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_AWARD_FAILED, error });
  }
}

function* removeContestBannerImageRequest(request) {
  try {
    const context = yield call(() => removeContestBannerImage(request.body));
    yield put({ type: actions.REMOVE_CONTEST_BANNER_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_BANNER_IMAGE_FAILED, error });
  }
}

function* removeContestCategoryRequest(request) {
  try {
    const context = yield call(() => removeContestCategory(request.body));
    yield put({ type: actions.REMOVE_CONTEST_CATEGORY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_CATEGORY_FAILED, error });
  }
}

function* removeContestCoordinatorRequest(request) {
  try {
    const context = yield call(() => removeContestCoordinator(request.body));
    yield put({ type: actions.REMOVE_CONTEST_COORDINATOR_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_COORDINATOR_FAILED, error });
  }
}

function* removeContestDocumentFolderRequest(request) {
  try {
    const context = yield call(() => removeContestDocumentFolder(request.body));
    yield put({ type: actions.REMOVE_CONTEST_DOCUMENT_FOLDER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_DOCUMENT_FOLDER_FAILED, error });
  }
}

function* removeContestDownloadableFileRequest(request) {
  try {
    const context = yield call(() => removeContestDownloadableFile(request.body));
    yield put({ type: actions.REMOVE_CONTEST_DOWNLOADABLE_FILE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_DOWNLOADABLE_FILE_FAILED, error });
  }
}

function* removeContestEntryFormRequest(request) {
  try {
    const context = yield call(() => removeContestEntryForm(request.body));
    yield put({ type: actions.REMOVE_CONTEST_ENTRY_FORM_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_ENTRY_FORM_FAILED, error });
  }
}

function* removeContestJudgeRequest(request) {
  try {
    const context = yield call(() => removeContestJudge(request.body));
    yield put({ type: actions.REMOVE_CONTEST_JUDGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_JUDGE_FAILED, error });
  }
}

function* removeContestJudgeInvitationRequest(request) {
  try {
    const context = yield call(() => removeContestJudgeInvitation(request.body));
    yield put({ type: actions.REMOVE_CONTEST_JUDGE_INVITATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_JUDGE_INVITATION_FAILED, error });
  }
}

function* removeContestJudgeProspectRequest(request) {
  try {
    const context = yield call(() => removeContestJudgeProspect(request.body));
    yield put({ type: actions.REMOVE_CONTEST_JUDGE_PROSPECT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_JUDGE_PROSPECT_FAILED, error });
  }
}

function* removeContestOptionalFeeRequest(request) {
  try {
    const context = yield call(() => removeContestOptionalFee(request.body));
    yield put({ type: actions.REMOVE_CONTEST_OPTIONAL_FEE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_OPTIONAL_FEE_FAILED, error });
  }
}

function* removeContestPromoCodeRequest(request) {
  try {
    const context = yield call(() => removeContestPromoCode(request.body));
    yield put({ type: actions.REMOVE_CONTEST_PROMO_CODE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_PROMO_CODE_FAILED, error });
  }
}

function* removeContestRegistrationRequest(request) {
  try {
    const context = yield call(() => removeContestRegistration(request.body));
    yield put({ type: actions.REMOVE_CONTEST_REGISTRATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_REGISTRATION_FAILED, error });
  }
}

function* removeContestScholarshipRequest(request) {
  try {
    const context = yield call(() => removeContestScholarship(request.body));
    yield put({ type: actions.REMOVE_CONTEST_SCHOLARSHIP_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_SCHOLARSHIP_FAILED, error });
  }
}

function* removeContestScoresheetRequest(request) {
  try {
    const context = yield call(() => removeContestScoresheet(request.body));
    yield put({ type: actions.REMOVE_CONTEST_SCORESHEET_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_SCORESHEET_FAILED, error });
  }
}

function* removeContestSponsorTierRequest(request) {
  try {
    const context = yield call(() => removeContestSponsorTier(request.body));
    yield put({ type: actions.REMOVE_CONTEST_SPONSOR_TIER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_SPONSOR_TIER_FAILED, error });
  }
}

function* removeContestSupportingProductTimeframeRequest(request) {
  try {
    const context = yield call(() => removeContestSupportingProductTimeframe(request.body));
    yield put({ type: actions.REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, error });
  }
}

function* removeContestTimeframeRequest(request) {
  try {
    const context = yield call(() => removeContestTimeframe(request.body));
    yield put({ type: actions.REMOVE_CONTEST_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_CONTEST_TIMEFRAME_FAILED, error });
  }
}

function* reorderContestApplicationFoldersRequest(request) {
  try {
    const context = yield call(() => reorderContestApplicationFolders(request.body));
    yield put({ type: actions.REORDER_CONTEST_APPLICATION_FOLDERS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_APPLICATION_FOLDERS_FAILED, error });
  }
}

function* reorderContestApplicationFormsRequest(request) {
  try {
    const context = yield call(() => reorderContestApplicationForms(request.body));
    yield put({ type: actions.REORDER_CONTEST_APPLICATION_FORMS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_APPLICATION_FORMS_FAILED, error });
  }
}

function* reorderContestAwardsRequest(request) {
  try {
    const context = yield call(() => reorderContestAwards(request.body));
    yield put({ type: actions.REORDER_CONTEST_AWARDS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_AWARDS_FAILED, error });
  }
}

function* reorderContestCategoriesRequest(request) {
  try {
    const context = yield call(() => reorderContestCategories(request.body));
    yield put({ type: actions.REORDER_CONTEST_CATEGORIES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_CATEGORIES_FAILED, error });
  }
}

function* reorderContestCoordinatorsRequest(request) {
  try {
    const context = yield call(() => reorderContestCoordinators(request.body));
    yield put({ type: actions.REORDER_CONTEST_COORDINATORS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_COORDINATORS_FAILED, error });
  }
}

function* reorderContestDocumentFoldersRequest(request) {
  try {
    const context = yield call(() => reorderContestDocumentFolders(request.body));
    yield put({ type: actions.REORDER_CONTEST_DOCUMENT_FOLDERS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_DOCUMENT_FOLDERS_FAILED, error });
  }
}

function* reorderContestEntryFormsRequest(request) {
  try {
    const context = yield call(() => reorderContestEntryForms(request.body));
    yield put({ type: actions.REORDER_CONTEST_ENTRY_FORMS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_ENTRY_FORMS_FAILED, error });
  }
}

function* reorderContestOptionalFeesRequest(request) {
  try {
    const context = yield call(() => reorderContestOptionalFees(request.body));
    yield put({ type: actions.REORDER_CONTEST_OPTIONAL_FEES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_OPTIONAL_FEES_FAILED, error });
  }
}

function* reorderContestScholarshipsRequest(request) {
  try {
    const context = yield call(() => reorderContestScholarships(request.body));
    yield put({ type: actions.REORDER_CONTEST_SCHOLARSHIPS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_SCHOLARSHIPS_FAILED, error });
  }
}


function* reorderContestScoresheetsRequest(request) {
  try {
    const context = yield call(() => reorderContestScoresheets(request.body));
    yield put({ type: actions.REORDER_CONTEST_SCORESHEETS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_SCORESHEETS_FAILED, error });
  }
}

function* reorderContestSponsorTiersRequest(request) {
  try {
    const context = yield call(() => reorderContestSponsorTiers(request.body));
    yield put({ type: actions.REORDER_CONTEST_SPONSOR_TIERS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_CONTEST_SPONSOR_TIERS_FAILED, error });
  }
}

function* requestContestJudgeVolunteerRequest(request) {
  try {
    const context = yield call(() => requestContestJudgeVolunteer(request.body));
    yield put({ type: actions.REQUEST_CONTEST_JUDGE_VOLUNTEER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REQUEST_CONTEST_JUDGE_VOLUNTEER_FAILED, error });
  }
}

function* requestContestJudgeVolunteerListRequest(request) {
  try {
    const context = yield call(() => requestContestJudgeVolunteerList(request.body));
    yield put({ type: actions.REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_FAILED, error });
  }
}

function* resetContestURLChanged() {
  yield put({ type: actions.RESET_CONTEST_URL_CHANGED_SUCCESS });
}

function* selectContestBannerImageRequest(request) {
  try {
    const context = yield call(() => selectContestBannerImage(request.body));
    yield put({ type: actions.SELECT_CONTEST_BANNER_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.SELECT_CONTEST_BANNER_IMAGE_FAILED, error });
  }
}

function* sendContestNotificationsRequest(request) {
  try {
    const context = yield call(() => sendContestNotifications(request.body));
    yield put({ type: actions.SEND_CONTEST_NOTIFICATIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.SEND_CONTEST_NOTIFICATIONS_FAILED, error });
  }
}

function* updateContestApplicationFolderRequest(request) {
  try {
    const context = yield call(() => updateContestApplicationFolder(request.body));
    yield put({ type: actions.UPDATE_CONTEST_APPLICATION_FOLDER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_APPLICATION_FOLDER_FAILED, error });
  }
}

function* updateContestApplicationFormRequest(request) {
  try {
    const context = yield call(() => updateContestApplicationForm(request.body));
    yield put({ type: actions.UPDATE_CONTEST_APPLICATION_FORM_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_APPLICATION_FORM_FAILED, error });
  }
}

function* updateContestApplicationFormStructureRequest(request) {
  try {
    const context = yield call(() => updateContestApplicationFormStructure(request.body));
    yield put({ type: actions.UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_FAILED, error });
  }
}

function* updateContestAwardRequest(request) {
  try {
    const context = yield call(() => updateContestAward(request.body));
    yield put({ type: actions.UPDATE_CONTEST_AWARD_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_AWARD_FAILED, error });
  }
}

function* updateContestCategoryRequest(request) {
  try {
    const context = yield call(() => updateContestCategory(request.body));
    yield put({ type: actions.UPDATE_CONTEST_CATEGORY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_CATEGORY_FAILED, error });
  }
}

function* updateContestConfigurationRequest(request) {
  try {
    const context = yield call(() => updateContestConfiguration(request.body));
    yield put({ type: actions.UPDATE_CONTEST_CONFIGURATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_CONFIGURATION_FAILED, error });
  }
}

function* updateContestConfirmationEmailRequest(request) {
  try {
    const context = yield call(() => updateContestConfirmationEmail(request.body));
    yield put({ type: actions.UPDATE_CONTEST_CONFIRMATION_EMAIL_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_CONFIRMATION_EMAIL_FAILED, error });
  }
}

function* updateContestConnectedEventRequest(request) {
  try {
    const context = yield call(() => updateContestConnectedEvent(request.body));
    yield put({ type: actions.UPDATE_CONTEST_CONNECTED_EVENT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_CONNECTED_EVENT_FAILED, error });
  }
}

function* updateContestCoordinatorRequest(request) {
  try {
    const context = yield call(() => updateContestCoordinator(request.body));
    yield put({ type: actions.UPDATE_CONTEST_COORDINATOR_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_COORDINATOR_FAILED, error });
  }
}

function* updateContestCustomPageSettingsRequest(request) {
  try {
    const context = yield call(() => updateContestCustomPageSettings(request.body));
    yield put({ type: actions.UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_FAILED, error });
  }
}

function* updateContestCustomPageTextRequest(request) {
  try {
    const context = yield call(() => updateContestCustomPageText(request.body));
    yield put({ type: actions.UPDATE_CONTEST_CUSTOM_PAGE_TEXT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_CUSTOM_PAGE_TEXT_FAILED, error });
  }
}

function* updateContestDatesRequest(request) {
  try {
    const context = yield call(() => updateContestDates(request.body));
    yield put({ type: actions.UPDATE_CONTEST_DATES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_DATES_FAILED, error });
  }
}

function* updateContestDocumentFolderRequest(request) {
  try {
    const context = yield call(() => updateContestDocumentFolder(request.body));
    yield put({ type: actions.UPDATE_CONTEST_DOCUMENT_FOLDER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_DOCUMENT_FOLDER_FAILED, error });
  }
}

function* updateContestDownloadableFileRequest(request) {
  try {
    const context = yield call(() => updateContestDownloadableFile(request.body));
    yield put({ type: actions.UPDATE_CONTEST_DOWNLOADABLE_FILE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_DOWNLOADABLE_FILE_FAILED, error });
  }
}

function* updateContestEntryFormRequest(request) {
  try {
    const context = yield call(() => updateContestEntryForm(request.body));
    yield put({ type: actions.UPDATE_CONTEST_ENTRY_FORM_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_ENTRY_FORM_FAILED, error });
  }
}

function* updateContestEntryFormStructureRequest(request) {
  try {
    const context = yield call(() => updateContestEntryFormStructure(request.body));
    yield put({ type: actions.UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_FAILED, error });
  }
}

function* updateContestGalleryRequest(request) {
  try {
    const context = yield call(() => updateContestGallery(request.body));
    yield put({ type: actions.UPDATE_CONTEST_GALLERY_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_GALLERY_FAILED, error });
  }
}


function* updateContestInstructionsRequest(request) {
  try {
    const context = yield call(() => updateContestInstructions(request.body));
    yield put({ type: actions.UPDATE_CONTEST_INSTRUCTIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_INSTRUCTIONS_FAILED, error });
  }
}

function* updateContestJudgeRequest(request) {
  try {
    const context = yield call(() => updateContestJudge(request.body));
    yield put({ type: actions.UPDATE_CONTEST_JUDGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_JUDGE_FAILED, error });
  }
}

function* updateContestJudgeInvitationRequest(request) {
  try {
    const context = yield call(() => updateContestJudgeInvitation(request.body));
    yield put({ type: actions.UPDATE_CONTEST_JUDGE_INVITATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_JUDGE_INVITATION_FAILED, error });
  }
}

function* updateContestJudgeProspectRequest(request) {
  try {
    const context = yield call(() => updateContestJudgeProspect(request.body));
    yield put({ type: actions.UPDATE_CONTEST_JUDGE_PROSPECT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_JUDGE_PROSPECT_FAILED, error });
  }
}

function* updateContestNameRequest(request) {
  try {
    const context = yield call(() => updateContestName(request.body));
    yield put({ type: actions.UPDATE_CONTEST_NAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_NAME_FAILED, error });
  }
}

function* updateContestOptionalFeeRequest(request) {
  try {
    const context = yield call(() => updateContestOptionalFee(request.body));
    yield put({ type: actions.UPDATE_CONTEST_OPTIONAL_FEE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_OPTIONAL_FEE_FAILED, error });
  }
}

function* updateContestPartnerPageRequest(request) {
  try {
    const context = yield call(() => updateContestPartnerPage(request.body));
    yield put({ type: actions.UPDATE_CONTEST_PARTNER_PAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_PARTNER_PAGE_FAILED, error });
  }
}

function* updateContestPromoCodeRequest(request) {
  try {
    const context = yield call(() => updateContestPromoCode(request.body));
    yield put({ type: actions.UPDATE_CONTEST_PROMO_CODE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_PROMO_CODE_FAILED, error });
  }
}

function* updateContestPublicVotingRequest(request) {
  try {
    const context = yield call(() => updateContestPublicVoting(request.body));
    yield put({ type: actions.UPDATE_CONTEST_PUBLIC_VOTING_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_PUBLIC_VOTING_FAILED, error });
  }
}

function* updateContestRegistrationRequest(request) {
  try {
    const context = yield call(() => updateContestRegistration(request.body));
    yield put({ type: actions.UPDATE_CONTEST_REGISTRATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_REGISTRATION_FAILED, error });
  }
}

function* updateContestRulesRequest(request) {
  try {
    const context = yield call(() => updateContestRules(request.body));
    yield put({ type: actions.UPDATE_CONTEST_RULES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_RULES_FAILED, error });
  }
}

function* updateContestScholarshipRequest(request) {
  try {
    const context = yield call(() => updateContestScholarship(request.body));
    yield put({ type: actions.UPDATE_CONTEST_SCHOLARSHIP_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_SCHOLARSHIP_FAILED, error });
  }
}

function* updateContestScholarshipRequirementsRequest(request) {
  try {
    const context = yield call(() => updateContestScholarshipRequirements(request.body));
    yield put({ type: actions.UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_FAILED, error });
  }
}

function* updateContestScoresheetRequest(request) {
  try {
    const context = yield call(() => updateContestScoresheet(request.body));
    yield put({ type: actions.UPDATE_CONTEST_SCORESHEET_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_SCORESHEET_FAILED, error });
  }
}

function* updateContestScoresheetCriteriaRequest(request) {
  try {
    const context = yield call(() => updateContestScoresheetCriteria(request.body));
    yield put({ type: actions.UPDATE_CONTEST_SCORESHEET_CRITERIA_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_SCORESHEET_CRITERIA_FAILED, error });
  }
}

function* updateContestScoringInstructionsRequest(request) {
  try {
    const context = yield call(() => updateContestScoringInstructions(request.body));
    yield put({ type: actions.UPDATE_CONTEST_SCORING_INSTRUCTIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_SCORING_INSTRUCTIONS_FAILED, error });
  }
}

function* updateContestSEORequest(request) {
  try {
    const context = yield call(() => updateContestSEO(request.body));
    yield put({ type: actions.UPDATE_CONTEST_SEO_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_SEO_FAILED, error });
  }
}

function* updateContestSponsorTierRequest(request) {
  try {
    const context = yield call(() => updateContestSponsorTier(request.body));
    yield put({ type: actions.UPDATE_CONTEST_SPONSOR_TIER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_SPONSOR_TIER_FAILED, error });
  }
}

function* updateContestSponsorOptionsRequest(request) {
  try {
    const context = yield call(() => updateContestSponsorOptions(request.body));
    yield put({ type: actions.UPDATE_CONTEST_SPONSOR_OPTIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_SPONSOR_OPTIONS_FAILED, error });
  }
}

function* updateContestSupportingProductTimeframeRequest(request) {
  try {
    const context = yield call(() => updateContestSupportingProductTimeframe(request.body));
    yield put({ type: actions.UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, error });
  }
}

function* updateContestTimeframeRequest(request) {
  try {
    const context = yield call(() => updateContestTimeframe(request.body));
    yield put({ type: actions.UPDATE_CONTEST_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTEST_TIMEFRAME_FAILED, error });
  }
}

function* uploadContestBannerImageRequest(request) {
  try {
    const context = yield call(() => uploadContestBannerImage(request.body));
    yield put({ type: actions.UPLOAD_CONTEST_BANNER_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_CONTEST_BANNER_IMAGE_FAILED, error });
  }
}

function* uploadContestDownloadableFileRequest(request) {
  try {
    const context = yield call(() => uploadContestDownloadableFile(request.body));
    yield put({ type: actions.UPLOAD_CONTEST_DOWNLOADABLE_FILE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_CONTEST_DOWNLOADABLE_FILE_FAILED, error });
  }
}

function* apiFailed(request) {
  toast.error(request.error.message);
  if (request.error.status === 403) {
    yield put({ type: userActions.CLEAR_USER });
  }
}

function* contestSagas() {
  yield takeEvery(actions.ARCHIVE_CONTEST_REQUEST, archiveContestRequest);
  yield takeEvery(actions.ASSIGN_CONTEST_AWARDS_REQUEST, assignContestAwardsRequest);
  yield takeEvery(actions.CLEAR_CONTEST_VOLUNTEERS, clearContestVolunteersRequest);
  yield takeEvery(actions.CLEAR_SCORING_PROGRESS_ENTRIES, clearScoringProgressEntriesRequest);
  yield takeEvery(actions.CLEAR_SCORING_PROGRESS_JUDGES, clearScoringProgressJudgesRequest);
  yield takeEvery(actions.CONVERT_CONTEST_JUDGE_PROSPECT_REQUEST, convertContestJudgeProspectRequest);
  yield takeEvery(actions.CREATE_CONTEST_REQUEST, createContestRequest);
  yield takeEvery(actions.CREATE_CONTEST_ARCHIVE_REQUEST, createContestArchiveRequest);
  yield takeEvery(actions.DOWNLOAD_CONTEST_ENTRY_FORM_REQUEST, downloadContestEntryFormRequest);
  yield takeEvery(actions.DOWNLOAD_CONTEST_REGISTRATIONS_REQUEST, downloadContestRegistrationsRequest);
  yield takeEvery(actions.DOWNLOAD_CONTEST_SCORES_REQUEST, downloadContestScoresRequest);
  yield takeEvery(actions.DOWNLOAD_CONTEST_WINNERS_REQUEST, downloadContestWinnersRequest);
  yield takeEvery(actions.DUPLICATE_APPLICATION_FORM_REQUEST, duplicateApplicationFormRequest);
  yield takeEvery(actions.DUPLICATE_CONTEST_REQUEST, duplicateContestRequest);
  yield takeEvery(actions.DUPLICATE_ENTRY_FORM_REQUEST, duplicateEntryFormRequest);
  yield takeEvery(actions.DUPLICATE_SCORESHEET_REQUEST, duplicateScoresheetRequest);
  yield takeEvery(actions.GET_CONTEST_REQUEST, getContestRequest);
  yield takeEvery(actions.GET_CONTEST_ARCHIVES_REQUEST, getContestArchivesRequest);
  yield takeEvery(actions.GET_CONTEST_LIST_REQUEST, getContestListRequest);
  yield takeEvery(actions.GET_CONTEST_PUBLIC_VOTES_REQUEST, getContestPublicVotesRequest);
  yield takeEvery(actions.GET_CONTEST_WINNERS_REQUEST, getContestWinnersRequest);
  yield takeEvery(actions.GET_SCORING_PROGRESS_BY_ENTRY_REQUEST, getScoringProgressByEntryRequest);
  yield takeEvery(actions.GET_SCORING_PROGRESS_BY_JUDGE_REQUEST, getScoringProgressByJudgeRequest);
  yield takeEvery(actions.GET_SCORING_PROGRESS_FOR_ENTRY_REQUEST, getScoringProgressForEntryRequest);
  yield takeEvery(actions.GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_REQUEST, getScoringProgressEntriesForJudgeRequest);
  yield takeEvery(actions.GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_REQUEST, getScoringProgressJudgesForEntryRequest);
  yield takeEvery(actions.REMOVE_CONTEST_BANNER_IMAGE_REQUEST, removeContestBannerImageRequest);
  yield takeEvery(actions.REMOVE_CONTEST_APPLICATION_FOLDER_REQUEST, removeContestApplicationFolderRequest);
  yield takeEvery(actions.REMOVE_CONTEST_APPLICATION_FORM_REQUEST, removeContestApplicationFormRequest);
  yield takeEvery(actions.REMOVE_CONTEST_ARCHIVE_REQUEST, removeContestArchiveRequest);
  yield takeEvery(actions.REMOVE_CONTEST_AWARD_REQUEST, removeContestAwardRequest);
  yield takeEvery(actions.REMOVE_CONTEST_CATEGORY_REQUEST, removeContestCategoryRequest);
  yield takeEvery(actions.REMOVE_CONTEST_COORDINATOR_REQUEST, removeContestCoordinatorRequest);
  yield takeEvery(actions.REMOVE_CONTEST_DOCUMENT_FOLDER_REQUEST, removeContestDocumentFolderRequest);
  yield takeEvery(actions.REMOVE_CONTEST_DOWNLOADABLE_FILE_REQUEST, removeContestDownloadableFileRequest);
  yield takeEvery(actions.REMOVE_CONTEST_ENTRY_FORM_REQUEST, removeContestEntryFormRequest);
  yield takeEvery(actions.REMOVE_CONTEST_JUDGE_REQUEST, removeContestJudgeRequest);
  yield takeEvery(actions.REMOVE_CONTEST_JUDGE_INVITATION_REQUEST, removeContestJudgeInvitationRequest);
  yield takeEvery(actions.REMOVE_CONTEST_JUDGE_PROSPECT_REQUEST, removeContestJudgeProspectRequest);
  yield takeEvery(actions.REMOVE_CONTEST_OPTIONAL_FEE_REQUEST, removeContestOptionalFeeRequest);
  yield takeEvery(actions.REMOVE_CONTEST_PROMO_CODE_REQUEST, removeContestPromoCodeRequest);
  yield takeEvery(actions.REMOVE_CONTEST_REGISTRATION_REQUEST, removeContestRegistrationRequest);
  yield takeEvery(actions.REMOVE_CONTEST_SCHOLARSHIP_REQUEST, removeContestScholarshipRequest);
  yield takeEvery(actions.REMOVE_CONTEST_SCORESHEET_REQUEST, removeContestScoresheetRequest);
  yield takeEvery(actions.REMOVE_CONTEST_SPONSOR_TIER_REQUEST, removeContestSponsorTierRequest);
  yield takeEvery(actions.REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST, removeContestSupportingProductTimeframeRequest);
  yield takeEvery(actions.REMOVE_CONTEST_TIMEFRAME_REQUEST, removeContestTimeframeRequest);
  yield takeEvery(actions.REORDER_CONTEST_APPLICATION_FOLDERS_REQUEST, reorderContestApplicationFoldersRequest);
  yield takeEvery(actions.REORDER_CONTEST_APPLICATION_FORMS_REQUEST, reorderContestApplicationFormsRequest);
  yield takeEvery(actions.REORDER_CONTEST_AWARDS_REQUEST, reorderContestAwardsRequest);
  yield takeEvery(actions.REORDER_CONTEST_CATEGORIES_REQUEST, reorderContestCategoriesRequest);
  yield takeEvery(actions.REORDER_CONTEST_COORDINATORS_REQUEST, reorderContestCoordinatorsRequest);
  yield takeEvery(actions.REORDER_CONTEST_DOCUMENT_FOLDERS_REQUEST, reorderContestDocumentFoldersRequest);
  yield takeEvery(actions.REORDER_CONTEST_ENTRY_FORMS_REQUEST, reorderContestEntryFormsRequest);
  yield takeEvery(actions.REORDER_CONTEST_OPTIONAL_FEES_REQUEST, reorderContestOptionalFeesRequest);
  yield takeEvery(actions.REORDER_CONTEST_SCHOLARSHIPS_REQUEST, reorderContestScholarshipsRequest);
  yield takeEvery(actions.REORDER_CONTEST_SCORESHEETS_REQUEST, reorderContestScoresheetsRequest);
  yield takeEvery(actions.REORDER_CONTEST_SPONSOR_TIERS_REQUEST, reorderContestSponsorTiersRequest);
  yield takeEvery(actions.REQUEST_CONTEST_JUDGE_VOLUNTEER_REQUEST, requestContestJudgeVolunteerRequest);
  yield takeEvery(actions.REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_REQUEST, requestContestJudgeVolunteerListRequest);
  yield takeEvery(actions.RESET_CONTEST_URL_CHANGED, resetContestURLChanged);
  yield takeEvery(actions.SELECT_CONTEST_BANNER_IMAGE_REQUEST, selectContestBannerImageRequest);
  yield takeEvery(actions.SEND_CONTEST_NOTIFICATIONS_REQUEST, sendContestNotificationsRequest);
  yield takeEvery(actions.UPDATE_CONTEST_APPLICATION_FOLDER_REQUEST, updateContestApplicationFolderRequest);
  yield takeEvery(actions.UPDATE_CONTEST_APPLICATION_FORM_REQUEST, updateContestApplicationFormRequest);
  yield takeEvery(actions.UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_REQUEST, updateContestApplicationFormStructureRequest);
  yield takeEvery(actions.UPDATE_CONTEST_AWARD_REQUEST, updateContestAwardRequest);
  yield takeEvery(actions.UPDATE_CONTEST_CATEGORY_REQUEST, updateContestCategoryRequest);
  yield takeEvery(actions.UPDATE_CONTEST_CONFIGURATION_REQUEST, updateContestConfigurationRequest);
  yield takeEvery(actions.UPDATE_CONTEST_CONFIRMATION_EMAIL_REQUEST, updateContestConfirmationEmailRequest);
  yield takeEvery(actions.UPDATE_CONTEST_CONNECTED_EVENT_REQUEST, updateContestConnectedEventRequest);
  yield takeEvery(actions.UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_REQUEST, updateContestCustomPageSettingsRequest);
  yield takeEvery(actions.UPDATE_CONTEST_CUSTOM_PAGE_TEXT_REQUEST, updateContestCustomPageTextRequest);
  yield takeEvery(actions.UPDATE_CONTEST_COORDINATOR_REQUEST, updateContestCoordinatorRequest);
  yield takeEvery(actions.UPDATE_CONTEST_DATES_REQUEST, updateContestDatesRequest);
  yield takeEvery(actions.UPDATE_CONTEST_DOCUMENT_FOLDER_REQUEST, updateContestDocumentFolderRequest);
  yield takeEvery(actions.UPDATE_CONTEST_DOWNLOADABLE_FILE_REQUEST, updateContestDownloadableFileRequest);
  yield takeEvery(actions.UPDATE_CONTEST_ENTRY_FORM_REQUEST, updateContestEntryFormRequest);
  yield takeEvery(actions.UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_REQUEST, updateContestEntryFormStructureRequest);
  yield takeEvery(actions.UPDATE_CONTEST_GALLERY_REQUEST, updateContestGalleryRequest);
  yield takeEvery(actions.UPDATE_CONTEST_INSTRUCTIONS_REQUEST, updateContestInstructionsRequest);
  yield takeEvery(actions.UPDATE_CONTEST_JUDGE_REQUEST, updateContestJudgeRequest);
  yield takeEvery(actions.UPDATE_CONTEST_JUDGE_INVITATION_REQUEST, updateContestJudgeInvitationRequest);
  yield takeEvery(actions.UPDATE_CONTEST_JUDGE_PROSPECT_REQUEST, updateContestJudgeProspectRequest);
  yield takeEvery(actions.UPDATE_CONTEST_NAME_REQUEST, updateContestNameRequest);
  yield takeEvery(actions.UPDATE_CONTEST_OPTIONAL_FEE_REQUEST, updateContestOptionalFeeRequest);
  yield takeEvery(actions.UPDATE_CONTEST_PARTNER_PAGE_REQUEST, updateContestPartnerPageRequest);
  yield takeEvery(actions.UPDATE_CONTEST_PUBLIC_VOTING_REQUEST, updateContestPublicVotingRequest);
  yield takeEvery(actions.UPDATE_CONTEST_PROMO_CODE_REQUEST, updateContestPromoCodeRequest);
  yield takeEvery(actions.UPDATE_CONTEST_REGISTRATION_REQUEST, updateContestRegistrationRequest);
  yield takeEvery(actions.UPDATE_CONTEST_RULES_REQUEST, updateContestRulesRequest);
  yield takeEvery(actions.UPDATE_CONTEST_SCHOLARSHIP_REQUEST, updateContestScholarshipRequest);
  yield takeEvery(actions.UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_REQUEST, updateContestScholarshipRequirementsRequest);
  yield takeEvery(actions.UPDATE_CONTEST_SCORESHEET_REQUEST, updateContestScoresheetRequest);
  yield takeEvery(actions.UPDATE_CONTEST_SCORESHEET_CRITERIA_REQUEST, updateContestScoresheetCriteriaRequest);
  yield takeEvery(actions.UPDATE_CONTEST_SCORING_INSTRUCTIONS_REQUEST, updateContestScoringInstructionsRequest);
  yield takeEvery(actions.UPDATE_CONTEST_SEO_REQUEST, updateContestSEORequest);
  yield takeEvery(actions.UPDATE_CONTEST_SPONSOR_TIER_REQUEST, updateContestSponsorTierRequest);
  yield takeEvery(actions.UPDATE_CONTEST_SPONSOR_OPTIONS_REQUEST, updateContestSponsorOptionsRequest);
  yield takeEvery(actions.UPDATE_CONTEST_TIMEFRAME_REQUEST, updateContestTimeframeRequest);
  yield takeEvery(actions.UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST, updateContestSupportingProductTimeframeRequest);
  yield takeEvery(actions.UPLOAD_CONTEST_BANNER_IMAGE_REQUEST, uploadContestBannerImageRequest);
  yield takeEvery(actions.UPLOAD_CONTEST_DOWNLOADABLE_FILE_REQUEST, uploadContestDownloadableFileRequest);
  
  yield takeEvery(actions.ARCHIVE_CONTEST_FAILED, apiFailed);
  yield takeEvery(actions.ASSIGN_CONTEST_AWARDS_FAILED, apiFailed);
  yield takeEvery(actions.CONVERT_CONTEST_JUDGE_PROSPECT_FAILED, apiFailed);
  yield takeEvery(actions.CREATE_CONTEST_FAILED, apiFailed);
  yield takeEvery(actions.CREATE_CONTEST_ARCHIVE_FAILED, apiFailed);
  yield takeEvery(actions.DOWNLOAD_CONTEST_ENTRY_FORM_FAILED, apiFailed);
  yield takeEvery(actions.DOWNLOAD_CONTEST_REGISTRATIONS_FAILED, apiFailed);
  yield takeEvery(actions.DOWNLOAD_CONTEST_SCORES_FAILED, apiFailed);
  yield takeEvery(actions.DOWNLOAD_CONTEST_WINNERS_FAILED, apiFailed);
  yield takeEvery(actions.DUPLICATE_APPLICATION_FORM_FAILED, apiFailed);
  yield takeEvery(actions.DUPLICATE_CONTEST_FAILED, apiFailed);
  yield takeEvery(actions.DUPLICATE_ENTRY_FORM_FAILED, apiFailed);
  yield takeEvery(actions.DUPLICATE_SCORESHEET_FAILED, apiFailed);
  yield takeEvery(actions.GET_CONTEST_FAILED, apiFailed);
  yield takeEvery(actions.GET_CONTEST_ARCHIVES_FAILED, apiFailed);
  yield takeEvery(actions.GET_CONTEST_LIST_FAILED, apiFailed);
  yield takeEvery(actions.GET_CONTEST_PUBLIC_VOTES_FAILED, apiFailed);
  yield takeEvery(actions.GET_CONTEST_WINNERS_FAILED, apiFailed);
  yield takeEvery(actions.GET_SCORING_PROGRESS_BY_ENTRY_FAILED, apiFailed);
  yield takeEvery(actions.GET_SCORING_PROGRESS_BY_JUDGE_FAILED, apiFailed);
  yield takeEvery(actions.GET_SCORING_PROGRESS_FOR_ENTRY_FAILED, apiFailed);
  yield takeEvery(actions.GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE_FAILED, apiFailed);
  yield takeEvery(actions.GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_APPLICATION_FOLDER_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_APPLICATION_FORM_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_ARCHIVE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_AWARD_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_BANNER_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_CATEGORY_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_COORDINATOR_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_DOCUMENT_FOLDER_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_DOWNLOADABLE_FILE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_ENTRY_FORM_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_JUDGE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_JUDGE_INVITATION_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_JUDGE_PROSPECT_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_OPTIONAL_FEE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_PROMO_CODE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_REGISTRATION_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_SCHOLARSHIP_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_SCORESHEET_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_SPONSOR_TIER_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_CONTEST_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_APPLICATION_FOLDERS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_APPLICATION_FORMS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_AWARDS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_CATEGORIES_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_COORDINATORS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_DOCUMENT_FOLDERS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_ENTRY_FORMS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_OPTIONAL_FEES_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_SCHOLARSHIPS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_SCORESHEETS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_CONTEST_SPONSOR_TIERS_FAILED, apiFailed);
  yield takeEvery(actions.REQUEST_CONTEST_JUDGE_VOLUNTEER_FAILED, apiFailed);
  yield takeEvery(actions.REQUEST_CONTEST_JUDGE_VOLUNTEER_LIST_FAILED, apiFailed);
  yield takeEvery(actions.SELECT_CONTEST_BANNER_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.SEND_CONTEST_NOTIFICATIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_APPLICATION_FOLDER_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_APPLICATION_FORM_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_APPLICATION_FORM_STRUCTURE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_AWARD_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_CATEGORY_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_CONFIGURATION_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_CONFIRMATION_EMAIL_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_CONNECTED_EVENT_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_COORDINATOR_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_CUSTOM_PAGE_TEXT_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_DATES_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_DOCUMENT_FOLDER_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_DOWNLOADABLE_FILE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_ENTRY_FORM_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_ENTRY_FORM_STRUCTURE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_GALLERY_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_INSTRUCTIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_JUDGE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_JUDGE_INVITATION_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_JUDGE_PROSPECT_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_NAME_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_OPTIONAL_FEE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_PARTNER_PAGE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_PROMO_CODE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_PUBLIC_VOTING_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_REGISTRATION_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_RULES_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_SCHOLARSHIP_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_SCORESHEET_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_SCORESHEET_CRITERIA_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_SCORING_INSTRUCTIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_SEO_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_SPONSOR_TIER_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_SPONSOR_OPTIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_CONTEST_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_CONTEST_BANNER_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_CONTEST_DOWNLOADABLE_FILE_FAILED, apiFailed);
}

export default contestSagas;
