import React, { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { connect, useSelector } from 'react-redux';
import getLeftDrawerContent from './leftContentService';
import getRightDrawerContent from './rightContentService';
import { Link } from 'react-router-dom';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import createLoadingSelector from 'src/redux/loading';

const DrawerComponent = ({ isOpen, isRequesting, setOpenState, right }) => {
  const ref = useRef();
  const drawerContent = useSelector(state => state.drawerContentStore?.drawerContent);
  const [content, setContent] = useState();
  const [helpPanelExpanded, setHelpPanelExpanded] = useState(false);

  useEffect(() => {
    if (drawerContent) {
      const updatedContent = right ? getRightDrawerContent(drawerContent) : getLeftDrawerContent(drawerContent);
      setContent(updatedContent);
      setHelpPanelExpanded(false);
    }
  }, [drawerContent]);

  const handleExpandedHelpPanelChange = (panel) => (event, isExpanded) => {
    setHelpPanelExpanded(isExpanded ? panel : false);
  }

  return (
    <section className={`${right ? 'right ' : ''}${isOpen ? 'open ' : ''}drawer`}>
      <div className="drawer-heading">
        <span className="drawer-heading-title">
          {right ? 'Help' : content?.title}
        </span>
        <FontAwesomeIcon
          className="icon close"
          icon={faXmark}
          onClick={() => setOpenState(false)}
        />
      </div>
      <div className="drawer-content" ref={ref}>
        { (!content || isRequesting) ?
          <LoadingCircle variant="dark" /> :
          <>
            { content?.text && <div className="drawer-content-item-text">{content.text}</div> }
            { (content?.list || []).map(content => {
                return (  
                  <div
                    className="drawer-content-item"
                    key={content.label}
                  >
                    <div className="drawer-content-item-title">{content.label}</div>
                    {
                      (content?.items || []).map(item => 
                        <Link
                          className={`drawer-content-item-link${item.page === drawerContent.key ? ' active' : ''}`}
                          key={item.url}
                          onClick={() => {
                            ref.current.scrollTo(0,0);
                            setOpenState(false)
                          }}
                          to={item.url}
                        >{item.label}</Link>
                      )
                    }
                  </div>
                )
              })
            }
            { !!(content?.tips || []).length && <>
                <div className="drawer-content-help-title">Things To Know:</div>
                {
                  (content?.tips || []).map((tip, index) => 
                    <p className="drawer-content-help-tip" key={index}>&bull; {parse(tip)}</p>
                  )
                }
              </>
            }
            { !!(content?.topics || []).length && <>
                <div className="drawer-content-help-title">How Do I...</div>
                {
                  (content?.topics || []).map((topic, index) => {
                    return (
                      <Accordion
                        key={topic.label}
                        expanded={helpPanelExpanded === `panel${index}`}
                        onChange={handleExpandedHelpPanelChange(`panel${index}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          sx={{ paddingLeft: '0', paddingRight: '0' }}
                        >
                          {topic.label}
                        </AccordionSummary>
                        <AccordionDetails sx={{ fontSize: '12px', fontWeight: '700', paddingLeft: '0', paddingRight: '0' }}>
                          {parse(topic.text)}
                        </AccordionDetails>
                      </Accordion>
                    )
                  })
                }
              </>
            }
          </>
        }
      </div>
    </section>
  )
};

const loadingSelector = createLoadingSelector([
  'GET_CLIENT',
  'GET_CONTEST',
  'GET_EVENT',
  'GET_PARADE',
  'GET_SURVEY'
]);
const mapStateToProps = (state) => ({ isRequesting: loadingSelector(state) });
export default connect(mapStateToProps)(DrawerComponent);
