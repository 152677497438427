import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyRequest } from 'src/redux/actions/surveys';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import SurveyGenerator from 'src/components/TemplateForms/Surveys/SurveyGenerator';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const SurveyQuestions = () => {
  const client = useSelector(state => state.clientStore?.client);
  const survey = useSelector(state => state.surveyStore?.survey);
  const surveyFetching = useSelector(state => state.loadingStore.GET_SURVEY);
  const { surveyURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!surveyURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!survey) {
      dispatch(getSurveyRequest({ clientURL: client.url, surveyURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'surveyQuestions', client, survey }));
    dispatch(updateDrawerContent({
      key: 'surveyQuestions',
      options: { client, survey } 
    }));
  }, [client, survey]);
  
  return (
    <section className="box">
      <div className="box-title">
        <h3>
          {
            surveyFetching ?
              <LoadingCircle variant="dark" /> :
              <span>Survey Questions / Structure</span>
          }
        </h3>
      </div>
      {
        !surveyFetching && (
        <div className="box-content">
          <SurveyGenerator />
        </div>
        )
      }
    </section>
  )
}

export default SurveyQuestions;