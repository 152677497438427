import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import {
  fieldTypes,
  inputTypes
} from 'src/constants';
import { updateEventPromoCodeRequest } from 'src/redux/actions/events';

const EventPromoCodeForm = ({ handleClose, promoCode }) => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const dispatch = useDispatch();

  const amount = 'amount';
  const percent = 'percent';
  const types = [{
    label: 'Amount',
    value: amount
  }, {
    label: 'Percent',
    value: percent
  }];

  const handleSubmit = (data) => {
    if (event.type === percent) {
      event.amount = event.percent;
    }
    event.amount = event.amount || 0;
    dispatch(updateEventPromoCodeRequest({ clientURL: client.url, eventURL: event.url, form: {...(promoCode || {}), ...data} }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          class: 'flex-1',
          label: 'Promo Code Key (Alphanumeric Only)',
          name: 'promoKey',
          type: fieldTypes.input,
          inputType: inputTypes.promoCode,
          value: promoCode?.promoKey || '',
          required: true
        }]
      }, {
        elements: [{
          label: 'Discount Type',
          name: 'type',
          type: fieldTypes.select,
          options: types,
          value: promoCode?.type || amount
        }, {
          label: 'Promo Code Amount',
          name: 'amount',
          hide: { key: 'type', value: percent },
          type: fieldTypes.input,
          inputType: inputTypes.currency,
          value: promoCode?.amount || ''
        }, {
          label: 'Promo Code Percentage',
          name: 'percent',
          hide: { key: 'type', value: amount },
          type: fieldTypes.input,
          inputType: inputTypes.percent,
          value: promoCode?.amount || ''
        }]
      }, {
        elements: [{
          label: 'Description (Optional)',
          name: 'description',
          type: fieldTypes.textarea,
          value: promoCode?.description || ''
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="promo-code-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_EVENT_PROMO_CODE'}
      />
    </section>
  )
}

export default EventPromoCodeForm;
