import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  MenuItem,
  Select
} from '@mui/material';
import ErrorLogs from 'src/pages/Network/Logs/ErrorLogs';
import LoginLogs from 'src/pages/Network/Logs/LoginLogs';
import UnauthorizedLogs from 'src/pages/Network/Logs/UnauthorizedLogs';

const Logs = () => {
  const error = 'error';
  const login = 'login';
  const unauthorized = 'unauthorized';
  const options = [{
    label: 'Error Logs',
    value: error
  }, {
    label: 'Failed Login Attempts',
    value: login
  }, {
    label: 'Unauthorized Access',
    value: unauthorized
  }];

  const [selectedLog, setSelectedLog] = useState(error);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'errorLogs' }));
    dispatch(updateDrawerContent({ key: 'networkErrorLogs' }));
  }, []);

  const handleLogChange = (value) => {
    setSelectedLog(value);
  };

  return <section className="logs grid">
    <section className="box">
      <div className="box-title">
        <h3>System Logging</h3>
      </div>
      <div className="box-content">
        <div>
          <label>Log Type:</label>
          <Select 
            onChange={event => handleLogChange(event.target.value)}
            sx={{ marginLeft: '8px' }}
            value={selectedLog}
          >{
            (options || [])?.map(item => {
              return (
                <MenuItem
                  key={item.value}
                  value={item.value}
                >{item.label}</MenuItem>
              )
            })
          }</Select>
        </div>
      </div>
    </section>
    { selectedLog === error && <ErrorLogs /> }
    { selectedLog === login && <LoginLogs /> }
    { selectedLog === unauthorized && <UnauthorizedLogs /> }
  </section>
}

export default Logs;
