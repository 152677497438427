import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  updateContestSEORequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { fieldTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ContestSEO = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const [configuration, setConfiguration] = useState(null);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  const handleSubmit = (event) => {
    dispatch(updateContestSEORequest({ clientURL: client.url, contestURL, form: event }));
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestSEO', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestSEO',
      options: { client, contest } 
    }));

    if (!configuration && contest) {
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Description',
              name: 'description',
              type: fieldTypes.textarea,
              value: contest?.description
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [client, contest]);

  return (
    <section className="contest-name box">
      <div className="box-title">
        <h3>
          {
            contestFetching ?
              <LoadingCircle variant="dark" /> :
              <span>Search Engine Optimization</span>
          }
        </h3>
      </div>
      {
        (!contestFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_CONTEST_SEO'}
          />
        </div>
        )
      }
    </section>
  )
}

export default ContestSEO;
