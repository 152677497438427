import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorLogsRequest } from 'src/redux/actions/app';
import { openDialog } from 'src/redux/actions/dialog';
import ResourceList from 'src/components/ResourceList';

const ErrorLogs = () => {
  const errorLogs = useSelector(state => state.appStore?.errorLogs);
  const logsFetching = useSelector(state => state.loadingStore.GET_ERROR_LOGS);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!logsFetching) {
      dispatch(getErrorLogsRequest());
    }
  }, []);

  const actions = [{
    color: 'danger',
    label: 'Clear All',
    variant: 'contained',
    fn: () => {
      dispatch(
        openDialog({
          key: 'clearErrorLogs'
        })
      )
    }
  }];
  
  return (
    <ResourceList
      actions={actions}
      fetching={logsFetching}
      list={errorLogs}
      notSelectable
      renderItem={log => 
        <div className="log">
          <div><span className="log-header">Date: </span><span className="log-date">{log.date}</span></div>
          <div><span className="log-header">Type: </span><span className="log-type">{log.type}</span></div>
          <div><span className="log-header">Message: </span><span className="log-message">{log.message}</span></div>
          <div><span className="log-header">URL: </span><span className="log-url">{log.url}</span></div>
          <div><span className="log-header">Args: </span><span className="log-args">{log.args || 'N/A'}</span></div>
          <div><span className="log-header">Stack: </span><span className="log-stack">{log.stack}</span></div>
        </div>
      }
      renderKey="id"
      title="Error Logs"
    />
  )
}

export default ErrorLogs;