import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyRequest } from 'src/redux/actions/surveys';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import AppCard from 'src/components/Elements/AppCard';

const Survey = () => {
  const client = useSelector(state => state.clientStore?.client);
  const survey = useSelector(state => state.surveyStore?.survey);
  const surveyFetching = useSelector(state => state.loadingStore.GET_SURVEY);
  const { surveyURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!surveyURL) {
    navigate('/');
  }

  useEffect(() => {
    dispatch(getSurveyRequest({ clientURL: client.url, surveyURL }));
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'survey', client, survey }));
    dispatch(updateDrawerContent({
      key: 'survey',
      options: { client, survey } 
    }));
  }, [client, survey]);

  return (
    <section className="app-grid">
      {
        surveyFetching ?
          <div className="app-grid-loading">
              <LoadingCircle variant="dark" />
          </div> :
          <>
            <div className="app-grid-row">
              <AppCard
                className="flex-1"
                title="Online Survey Portal"
              >
                <div className="app-grid-title">
                  <div className="app-grid-title-details">
                    <h1>
                      {survey?.name}
                    </h1>
                  </div>
                </div>
              </AppCard>
              <div className="card-group">
                <AppCard
                  className="shrinkable"
                  title="Important URLs"
                >
                  <div className="app-card-link">
                    <label>Public Landing Page:</label>
                    <Link
                      className="item"
                      target="_blank"
                      to={`https://${client.url}.cpjam.com/${survey?.url}`}
                    >
                      {`https://${client.url}.cpjam.com/${survey?.url}`}
                    </Link>
                  </div>
                </AppCard>
              </div>
            </div>
          </>
      }
    </section>
  )
}

export default Survey;
