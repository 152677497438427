export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILED = 'CHANGE_EMAIL_FAILED';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const CLEAR_ERROR_LOGS_REQUEST = 'CLEAR_ERROR_LOGS_REQUEST';
export const CLEAR_ERROR_LOGS_SUCCESS = 'CLEAR_ERROR_LOGS_SUCCESS';
export const CLEAR_ERROR_LOGS_FAILED = 'CLEAR_ERROR_LOGS_FAILED';

export const CLEAR_LOGIN_LOGS_REQUEST = 'CLEAR_LOGIN_LOGS_REQUEST';
export const CLEAR_LOGIN_LOGS_SUCCESS = 'CLEAR_LOGIN_LOGS_SUCCESS';
export const CLEAR_LOGIN_LOGS_FAILED = 'CLEAR_LOGIN_LOGS_FAILED';

export const CLEAR_UNAUTHORIZED_LOGS_REQUEST = 'CLEAR_UNAUTHORIZED_LOGS_REQUEST';
export const CLEAR_UNAUTHORIZED_LOGS_SUCCESS = 'CLEAR_UNAUTHORIZED_LOGS_SUCCESS';
export const CLEAR_UNAUTHORIZED_LOGS_FAILED = 'CLEAR_UNAUTHORIZED_LOGS_FAILED';

export const CLEAR_FORCE_RELOAD = 'CLEAR_FORCE_RELOAD';

export const GET_CURRENT_RESOURCES_REQUEST = 'GET_CURRENT_RESOURCES_REQUEST';
export const GET_CURRENT_RESOURCES_SUCCESS = 'GET_CURRENT_RESOURCES_SUCCESS';
export const GET_CURRENT_RESOURCES_FAILED = 'GET_CURRENT_RESOURCES_FAILED';

export const GET_ERROR_LOGS_REQUEST = 'GET_ERROR_LOGS_REQUEST';
export const GET_ERROR_LOGS_SUCCESS = 'GET_ERROR_LOGS_SUCCESS';
export const GET_ERROR_LOGS_FAILED = 'GET_ERROR_LOGS_FAILED';

export const GET_LOGIN_LOGS_REQUEST = 'GET_LOGIN_LOGS_REQUEST';
export const GET_LOGIN_LOGS_SUCCESS = 'GET_LOGIN_LOGS_SUCCESS';
export const GET_LOGIN_LOGS_FAILED = 'GET_LOGIN_LOGS_FAILED';

export const GET_UNAUTHORIZED_LOGS_REQUEST = 'GET_UNAUTHORIZED_LOGS_REQUEST';
export const GET_UNAUTHORIZED_LOGS_SUCCESS = 'GET_UNAUTHORIZED_LOGS_SUCCESS';
export const GET_UNAUTHORIZED_LOGS_FAILED = 'GET_UNAUTHORIZED_LOGS_FAILED';

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILED = 'GET_INVOICES_FAILED';

export const GET_PASSWORD_REQUESTS_REQUEST = 'GET_PASSWORD_REQUESTS_REQUEST';
export const GET_PASSWORD_REQUESTS_SUCCESS = 'GET_PASSWORD_REQUESTS_SUCCESS';
export const GET_PASSWORD_REQUESTS_FAILED = 'GET_PASSWORD_REQUESTS_FAILED';

export const GET_SYSTEM_NOTIFICATIONS_REQUEST = 'GET_SYSTEM_NOTIFICATIONS_REQUEST';
export const GET_SYSTEM_NOTIFICATIONS_SUCCESS = 'GET_SYSTEM_NOTIFICATIONS_SUCCESS';
export const GET_SYSTEM_NOTIFICATIONS_FAILED = 'GET_SYSTEM_NOTIFICATIONS_FAILED';

export const MIGRATE_USER_REQUEST = 'MIGRATE_USER_REQUEST';
export const MIGRATE_USER_SUCCESS = 'MIGRATE_USER_SUCCESS';
export const MIGRATE_USER_FAILED = 'MIGRATE_USER_FAILED';

export const REMOVE_INVOICE_REQUEST = 'REMOVE_INVOICE_REQUEST';
export const REMOVE_INVOICE_SUCCESS = 'REMOVE_INVOICE_SUCCESS';
export const REMOVE_INVOICE_FAILED = 'REMOVE_INVOICE_FAILED';

export const REMOVE_INVOICE_LINE_ITEM_REQUEST = 'REMOVE_INVOICE_LINE_ITEM_REQUEST';
export const REMOVE_INVOICE_LINE_ITEM_SUCCESS = 'REMOVE_INVOICE_LINE_ITEM_SUCCESS';
export const REMOVE_INVOICE_LINE_ITEM_FAILED = 'REMOVE_INVOICE_LINE_ITEM_FAILED';

export const REMOVE_SYSTEM_NOTIFICATION_REQUEST = 'REMOVE_SYSTEM_NOTIFICATION_REQUEST';
export const REMOVE_SYSTEM_NOTIFICATION_SUCCESS = 'REMOVE_SYSTEM_NOTIFICATION_SUCCESS';
export const REMOVE_SYSTEM_NOTIFICATION_FAILED = 'REMOVE_SYSTEM_NOTIFICATION_FAILED';

export const SEND_NOTIFICATIONS_REQUEST = 'SEND_NOTIFICATIONS_REQUEST';
export const SEND_NOTIFICATIONS_SUCCESS = 'SEND_NOTIFICATIONS_SUCCESS';
export const SEND_NOTIFICATIONS_FAILED = 'SEND_NOTIFICATIONS_FAILED';

export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAILED = 'UPDATE_INVOICE_FAILED';

export const UPDATE_SYSTEM_NOTIFICATION_REQUEST = 'UPDATE_SYSTEM_NOTIFICATION_REQUEST';
export const UPDATE_SYSTEM_NOTIFICATION_SUCCESS = 'UPDATE_SYSTEM_NOTIFICATION_SUCCESS';
export const UPDATE_SYSTEM_NOTIFICATION_FAILED = 'UPDATE_SYSTEM_NOTIFICATION_FAILED';

export function changeEmailRequest (body) {
  return {
    body,
    type: CHANGE_EMAIL_REQUEST
  }
}

export function changePasswordRequest (body) {
  return {
    body,
    type: CHANGE_PASSWORD_REQUEST
  }
}

export function clearErrorLogsRequest (body) {
  return {
    body,
    type: CLEAR_ERROR_LOGS_REQUEST
  }
}

export function clearLoginLogsRequest (body) {
  return {
    body,
    type: CLEAR_LOGIN_LOGS_REQUEST
  }
}

export function clearUnauthorizedLogsRequest (body) {
  return {
    body,
    type: CLEAR_UNAUTHORIZED_LOGS_REQUEST
  }
}

export function clearForceReloadRequest () {
  return {
    type: CLEAR_FORCE_RELOAD
  }
}

export function getCurrentResourcesRequest (body) {
  return {
    body,
    type: GET_CURRENT_RESOURCES_REQUEST
  }
}

export function getErrorLogsRequest (body) {
  return {
    body,
    type: GET_ERROR_LOGS_REQUEST
  }
}

export function getLoginLogsRequest (body) {
  return {
    body,
    type: GET_LOGIN_LOGS_REQUEST
  }
}

export function getUnauthorizedLogsRequest (body) {
  return {
    body,
    type: GET_UNAUTHORIZED_LOGS_REQUEST
  }
}

export function getInvoicesRequest (body) {
  return {
    body,
    type: GET_INVOICES_REQUEST
  }
}

export function getPasswordRequestsRequest (body) {
  return {
    body,
    type: GET_PASSWORD_REQUESTS_REQUEST
  }
}

export function getSystemNotificationsRequest (body) {
  return {
    body,
    type: GET_SYSTEM_NOTIFICATIONS_REQUEST
  }
}

export function migrateUserRequest (body) {
  return {
    body,
    type: MIGRATE_USER_REQUEST
  }
}

export function removeInvoiceRequest (body) {
  return {
    body,
    type: REMOVE_INVOICE_REQUEST
  }
}

export function removeInvoiceLineItemRequest (body) {
  return {
    body,
    type: REMOVE_INVOICE_LINE_ITEM_REQUEST
  }
}

export function removeSystemNotificationRequest (body) {
  return {
    body,
    type: REMOVE_SYSTEM_NOTIFICATION_REQUEST
  }
}

export function sendNotificationsRequest (body) {
  return {
    body,
    type: SEND_NOTIFICATIONS_REQUEST
  }
}

export function updateInvoiceRequest (body) {
  return {
    body,
    type: UPDATE_INVOICE_REQUEST
  }
}

export function updateSystemNotificationRequest (body) {
  return {
    body,
    type: UPDATE_SYSTEM_NOTIFICATION_REQUEST
  }
}
