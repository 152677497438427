import { fieldTypes, formFieldPatterns, inputTypes } from 'src/constants';
import moment from 'moment';

const isEmpty = ({ label, value }) => {
  if (typeof value === 'number') {
    return isNaN(value) ? `${label} is required` : undefined;
  }

  if (typeof value === 'string') {
    return value?.trim() === '' ? `${label} is required` : undefined;
  }

  return !value ? `${label} is required` : undefined;
}

const verifyWordLimit = ({ maxWords, value }) => {
  if (maxWords) {
    const words =
      (value || '').replace(/,/g, ' ')
        .split(/[\s]+/)
        .filter(word => {
          return word.trim() !== '';
        });
    return words.length > maxWords ? 'Maximum length exceeded' : undefined;
  }

  return undefined;
}

const verifyPercentage = ({ required, value }) => {
  if (!required && (!value || value.trim() === '')) {
    return undefined;
  }
  
  return (value < 0 || value > 100) ? `Valid percentage required` : undefined;
}

const verifyRequiredInput = ({ label, required, value }) => {
  return required ? isEmpty({ label, value }) : undefined;
}

const verifyRequiredPattern = ({ label, pattern, required, value }) => {
  if (!required && (!value || value.trim() === '')) {
    return undefined;
  }
  
  return !pattern.test(value) ? `Valid ${label} required` : undefined;
}

const verifyNotRestricted = ({ restricted, value }) => {
  if ((restricted || []).includes((value || '').toLowerCase())) {
    return 'Restricted value';
  }

  return undefined;
}

export const validateFormField = ({ inputType, maxWords, required, restricted, type, value }) => {
  let parsedValue;

  switch(type) {
    case fieldTypes.checkbox: return verifyRequiredInput({ label: 'This box', required, value: !!value }) ||
      undefined;

    case fieldTypes.textarea: return verifyRequiredInput({ label: 'Value', required, value }) ||
      verifyWordLimit({ maxWords, value }) ||
      undefined;

    case fieldTypes.select: return verifyRequiredInput({ label: 'Value', required, value }) ||
      undefined;

    case fieldTypes.input: 
      switch(inputType) {
        case inputTypes.text: return verifyRequiredInput({ label: 'Value', required, value }) ||
          verifyNotRestricted({ restricted, value }) ||
          undefined;

        case inputTypes.currency: return verifyRequiredInput({ label: 'Value', required, value }) ||
          undefined;

        case inputTypes.url: return verifyRequiredInput({ label: 'URL', required, value }) ||
          verifyNotRestricted({ restricted, value }) ||
          verifyRequiredPattern({ label: 'URL', pattern: formFieldPatterns.urlFragment, required, value }) ||
          undefined;

        case inputTypes.website: return verifyRequiredInput({ label: 'URL', required, value }) ||
          verifyRequiredPattern({ label: 'URL', pattern: formFieldPatterns.http, required, value }) ||
          undefined; 

        case inputTypes.categoryLabel: return verifyRequiredInput({ label: 'Category label', required, value }) ||
          verifyRequiredPattern({ label: 'category label', pattern: formFieldPatterns.catLabel, required, value }) ||
          undefined;

        case inputTypes.email: return verifyRequiredInput({ label: 'Email address', required, value }) ||
          verifyRequiredPattern({ label: 'email address', pattern: formFieldPatterns.email, required, value }) ||
          undefined;

        case inputTypes.phone: const filteredValue = (value || '').replace(/[^0-9]/g, '');
          return verifyRequiredInput({ label: 'Phone number', required, value: filteredValue }) ||
            verifyRequiredPattern({ label: 'phone number', pattern: formFieldPatterns.phone, required, value: filteredValue }) ||
            undefined;

        case inputTypes.password: return verifyRequiredInput({ label: 'Password', required, value }) ||
          verifyRequiredPattern({ label: 'password', pattern: formFieldPatterns.password, required, value }) ||
          undefined;

        case inputTypes.passwordAny: return verifyRequiredInput({ label: 'Password', required, value }) ||
          undefined;

        case inputTypes.promoCode: return verifyRequiredInput({ label: 'Promotional code', required, value }) ||
          verifyRequiredPattern({ label: 'promotional code', pattern: formFieldPatterns.promoCode, required, value }) ||
          undefined;

        case inputTypes.date: 
          if (!required) {
            return undefined;
          }
          parsedValue = moment.isMoment(value) ? value : moment.utc(value);

          return parsedValue.isValid() ? undefined : 'Valid date required';

        case inputTypes.dateTime: 
          if (!required) {
            return undefined;
          }
          parsedValue = moment.isMoment(value) ? value : moment.utc(value);

          return parsedValue.isValid() ? undefined : 'Valid date/time required';

        case inputTypes.percent:
          return verifyRequiredInput({ label: 'Percentage', required, value }) ||
          verifyPercentage({ required, value }) ||
          undefined;

        case inputTypes.zip: return verifyRequiredInput({ label: 'Zip code', required, value }) ||
          verifyRequiredPattern({ label: 'zip code', pattern: formFieldPatterns.zipcode, required, value }) ||
          undefined;

        default: return;
      }
    default: return;
  }
}

