import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadContestRegistrationsRequest,
  getContestRequest,
  updateContestRegistrationRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import UserProfile from 'src/components/UserProfile';
import { currency } from 'src/utilities/strings';
import { Button } from '@mui/material';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import { purchaseTypes } from 'src/constants';
import { faFileArrowDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContestRegistrationList = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const user = useSelector(state => state.userStore?.user);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const downloadingRegistrations = useSelector(state => state.loadingStore.DOWNLOAD_CONTEST_REGISTRATIONS);
  const { contestURL } = useParams();
  const [totalItems, setTotalItems] = useState();
  const [totalRevenue, setTotalRevenue] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    if (contest?.registrations) {
      setTotalRevenue(contest.registrations.reduce((acc, registration) => 
        acc + registration.items.reduce((acc, item) => acc + (item.quantity * item.price), 0), 0));
      setTotalItems(contest.registrations.reduce((acc, registration) => 
        acc + registration.items.reduce((acc, item) => acc + (item.itemID === 'promoID' ? 0 : item.quantity), 0), 0));
    }
  }, [contest?.registrations]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestRegistrationList', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestRegistrationList',
      options: { client, contest } 
    }));
  }, [client, contest]);

  const handleRegistrationsDownload = () => {
    dispatch(downloadContestRegistrationsRequest({ clientURL: client?.url, contestURL: contest?.url, contestID: contest?.contestID }));
  }

  const onSubmit = ({ form, transactionID }) => {
    dispatch(updateContestRegistrationRequest({
      clientURL: client?.url,
      contestURL: contest?.url,
      transactionID,
      form
    }));
  };

  const getAvailableTransactionOptions = () => {
    return {
      [purchaseTypes.event]: () => [...(contest.categories || []).map(category => {
        return {
          label: `${category.label}: ${category.description}`,
          value: category.categoryID
        };
      }), ...(contest.fees || []).map(fee => {
        return {
          label: fee.name,
          value: fee.feeID
        };
      }), {
        label: 'Promotional Discount',
        value: 'promoID'
      }],
      [purchaseTypes.sponsor]: () => {
        if (!!(contest.sponsorTiers || []).length) {
          return [...(contest.sponsorTiers || []).map(tier => {
            return {
              label: tier.name,
              value: tier.sponsorTierID
            };
          }), {
            label: 'Promotional Discount',
            value: 'promoID'
          }];
        }
    
        return [{
          label: 'General Sponsorship',
          value: 'null'
        }, {
          label: 'Promotional Discount',
          value: 'promoID'
        }];
      }
    }
  }

  const adminActions = [{
    label: 'Edit',
    requiresSelection: true,
    fn: registration => {
      const { items, purchaseType, transactionID, user } = registration;
      const options = getAvailableTransactionOptions();
      
      dispatch(openModal({
        props: {
          items,
          onSubmit,
          options,
          purchaseType,
          transactionID,
          updatePrefix: 'UPDATE_CONTEST_REGISTRATION',
          user
        },
        key: 'editContestRegistration'
      }))
    }
  }];

  const userActions = [{
    label: 'View',
    requiresSelection: true,
    fn: registration => {
      const { items } = registration;
      dispatch(openModal({
        props: { items },
        key: 'viewContestRegistration'
      }))
    }
  }];

  const actions = [{
    label: 'Receipt',
    requiresSelection: true,
    fn: registration => window.open(`https://${client.url}.cpjam.com/${contest?.url}/receipts/${registration.transactionID}`)
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: registration => {
      dispatch(
        openDialog({
          props: { client, contest, registration },
          key: 'removeContestRegistration'
        })
      )
    }
  }];

  const filters = {
    searchBy: ['fullName', 'email']
  };

  return (
    <section className="registration-list grid">
      <section className="box">
        <div className="box-title">
          <h3>
            {
              contestFetching ?
                <LoadingCircle variant="dark" /> :
                <span>Registration List Summary
                {
                  (downloadingRegistrations) && (
                    <LoadingCircle variant="dark" />
                  )
                }
                </span>
            }
          </h3>
        </div>
        {
          !contestFetching && (
          <div className="box-content options">
            <div className="total">
              <div className="total-revenue">Total Revenue: {currency(totalRevenue || 0)}</div>
              <div className="total-items">Total Items: {totalItems}</div>
            </div>
            <Button
              color="confirm"
              disabled={downloadingRegistrations}
              onClick={handleRegistrationsDownload}
              variant="contained"
            >
              { downloadingRegistrations ? <LoadingCircles /> : <FontAwesomeIcon icon={faFileArrowDown} /> }&nbsp;Registrations
            </Button>
          </div>
          )
        }
      </section>
      <ResourceList
        actions={user?.admin ? [...adminActions, ...actions] : [...userActions, ...actions]}
        fetching={contestFetching}
        filters={filters}
        list={contest?.registrations}
        pageSize={20}
        renderHeaders={() => 
          <div className="registration">
            <div className="registration-owner">Paid By</div>
            <div className="registration-date">Invoice Date</div>
            <div className="registration-items">Total Items</div>
            <div className="registration-price">Total Price</div>
          </div>
        }
        renderItem={invoice => 
          <div className="registration">
            <div className="registration-owner"><UserProfile showEmail user={invoice.user}/></div>
            <div className="registration-date">{invoice.date}</div>
            <div className="registration-items"><span className="mobile-display">Total Items: </span>{invoice.items.reduce((acc, item) => acc + (item.itemID === 'promoID' ? 0 : item.quantity), 0)}</div>
            <div className="registration-price"><span className="mobile-display">Total Price: </span>{currency(invoice.items.reduce((acc, item) => acc + (item.quantity * item.price), 0))}</div>
          </div>
        }
        renderKey="transactionID"
        searchProp="user"
        title="Registration List"
      />
    </section>
  )
}

export default ContestRegistrationList;
