const breadcrumbHierarchy = {
  home: {
    label: () => 'Home',
    url: () => '/',
    children: {
      network: {
        label: () => 'Network Administration',
        url: () => '/network',
        children: {
          clients: {
            label: () => 'Manage Clients',
            url: () => '/clients'
          },
          invoices: {
            label: () => 'Invoices',
            url: () => '/invoices'
          },
          emailAdministrators: {
            label: () => 'Email Administrators',
            url: () => '/email-administrators'
          },
          systemNotifications: {
            label: () => 'System Notifications',
            url: () => '/system-notifications'
          },
          migrateUser: {
            label: () => 'User Migration',
            url: () => '/migrate-user'
          },
          changeEmail: {
            label: () => 'Email Change',
            url: () => '/change-email'
          },
          changePassword: {
            label: () => 'Password Change',
            url: () => '/change-password'
          },
          currentResources: {
            label: () => 'Current Resources',
            url: () => '/current-resources'
          },
          errorLogs: {
            label: () => 'System Logging',
            url: () => '/system-logs'
          },
          passwordRequests: {
            label: () => 'Password Change Requests',
            url: () => '/password-requests'
          },
          viewUser: {
            label: () => 'View User',
            url: () => '/view-user'
          }
        }
      },
      client: {
        label: ({client}) => `${client?.name || ''}`,
        url: ({client}) => `${client?.url}`,
        children: {
          clientInvoicing: {
            label: () => 'Invoices',
            url: ({client}) => `/${client?.url}/invoices`
          },
          clientAdministrative: {
            label: () => 'Administrative Rights',
            url: ({client}) => `/${client?.url}/administrative`
          },
          clientOptions: {
            label: () => 'Advanced Configuration',
            url: ({client}) => `/${client?.url}/options`
          },
          contests: {
            label: () => 'Awards Programs',
            url: ({client}) => `/${client?.url}/contests`,
            children: {
              contest: {
                label: ({contest}) => `${contest?.name || ''}`,
                url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}`,
                children: {
                  contestArchives: {
                    label: () => 'Download Entry Files',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/archives`
                  },
                  contestAwards: {
                    label: () => 'Assignable Awards',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/awards`
                  },
                  contestBannerImage: {
                    label: () => 'Banner Image',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/banner`
                  },
                  contestCategories: {
                    label: () => 'Categories',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/categories`
                  },
                  contestConfiguration: {
                    label: () => 'Advanced Configuration',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/configuration`
                  },
                  contestConfirmWinners: {
                    label: () => 'Confirm Winners',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/confirm-winners`
                  },
                  contestConfirmationEmail: {
                    label: () => 'Confirmation Email',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/confirmation-email`
                  },
                  contestConnectedEvent: {
                    label: () => 'Connected Event',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/event`
                  },
                  contestCoordinators: {
                    label: () => 'Coordinators',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/coordinators`
                  },
                  contestCustomPage: {
                    label: () => 'Custom Page',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/custom-page`
                  },
                  contestDates: {
                    label: () => 'Dates',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/dates`
                  },
                  contestDocumentFolders: {
                    label: () => 'Document Folders',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/document-folders`
                  },
                  contestDownloadableFiles: {
                    label: () => 'Downloadable Files',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/files`
                  },
                  contestEntryForms: {
                    label: () => 'Entry Forms',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/entry-forms`
                  },
                  contestFinalReports: {
                    label: () => 'Download Reports',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/reports`
                  },
                  contestGallery: {
                    label: () => 'Winners Gallery',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/gallery`
                  },
                  contestJudges: {
                    label: () => 'Assign Judges',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/judges`
                  },
                  contestName: {
                    label: () => 'Name & URL',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/name`
                  },
                  contestNotifications: {
                    label: () => 'Notifications',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/notifications`
                  },
                  contestOptionalFees: {
                    label: () => 'Optional Fees',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/fees`
                  },
                  contestOptionalInstructions: {
                    label: () => 'Optional Instructions',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/instructions`
                  },
                  contestPartnerPage: {
                    label: () => 'Partner Page Content',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/partner-page`
                  },
                  contestPreview: {
                    label: () => 'Awards Program Previews',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/preview`
                  },
                  contestPromoCodes: {
                    label: () => 'Promotional Codes',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/promo-codes`
                  },
                  contestPublicVoting: {
                    label: () => 'Public Voting',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/public-voting`
                  },
                  contestRegistrationList: {
                    label: () => 'Registration List',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/registration-list`
                  },
                  contestRules: {
                    label: () => 'Rules & Regulations',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/rules`
                  },
                  contestScholarships: {
                    label: () => 'Scholarships/Grants',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/scholarships`
                  },
                  contestScoresheets: {
                    label: () => 'Scoresheets',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/scoresheets`
                  },
                  contestScoringProgress: {
                    label: () => 'Scoring Progress',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/scoring-progress`
                  },
                  contestSEO: {
                    label: () => 'Search Engine Optimization',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/seo`
                  },
                  contestSponsorOptions: {
                    label: () => 'Sponsor Tiers & Options',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/sponsor-options`
                  },
                  contestSponsorTimeframes: {
                    label: () => 'Sponsor Pricing & Availability',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/sponsor-timeframes`
                  },
                  contestTimeframes: {
                    label: () => 'Pricing & Availability',
                    url: ({client, contest}) => `/${client?.url}/contests/${contest?.url}/timeframes`
                  },
                }
              },
            }
          },
          events: {
            label: () => 'Events',
            url: ({client}) => `/${client?.url}/events`,
            children: {
              event: {
                label: ({event}) => `${event?.name || ''}`,
                url: ({client, event}) => `/${client?.url}/events/${event?.url}`,
                children: {
                  eventAttendance: {
                    label: () => 'Attendance',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/attendance`
                  },
                  eventBannerImage: {
                    label: () => 'Banner Image',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/banner`
                  },
                  eventConfiguration: {
                    label: () => 'Advanced Configuration',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/configuration`
                  },
                  eventConfirmationEmail: {
                    label: () => 'Confirmation Email',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/confirmation-email`
                  },
                  eventConnectedContest: {
                    label: () => 'Connected Awards Program',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/awards-program`
                  },
                  eventCoordinators: {
                    label: () => 'Coordinators',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/coordinators`
                  },
                  eventDetails: {
                    label: () => 'Details',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/details`
                  },
                  eventDownloadableFiles: {
                    label: () => 'Downloadable Files',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/files`
                  },
                  eventLocation: {
                    label: () => 'Location & Date/Time',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/location`
                  },
                  eventName: {
                    label: () => 'Name & URL',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/name`
                  },
                  eventNotifications: {
                    label: () => 'Notifications',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/notifications`
                  },
                  eventOptionalFees: {
                    label: () => 'Optional Fees',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/fees`
                  },
                  eventPartnerPage: {
                    label: () => 'Partner Page Content',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/partner-page`
                  },
                  eventPromoCodes: {
                    label: () => 'Promotional Codes',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/promo-codes`
                  },
                  eventPreview: {
                    label: () => 'Event Previews',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/preview`
                  },
                  eventRegistrationList: {
                    label: () => 'Registration List',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/registration-list`
                  },
                  eventRegistrationQuestions: {
                    label: () => 'Optional Registration Questions',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/registration-questions`
                  },
                  eventSEO: {
                    label: () => 'Search Engine Optimization',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/seo`
                  },
                  eventSlideshowImages: {
                    label: () => 'Slideshow Images',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/slideshow`
                  },
                  eventSponsorOptions: {
                    label: () => 'Sponsor Tiers & Options',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/sponsor-options`
                  },
                  eventSponsorTimeframes: {
                    label: () => 'Sponsor Pricing & Availability',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/sponsor-timeframes`
                  },
                  eventTickets: {
                    label: () => 'Tickets',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/tickets`
                  },
                  eventTimeframes: {
                    label: () => 'Pricing & Availability',
                    url: ({client, event}) => `/${client?.url}/events/${event?.url}/timeframes`
                  },
                }
              },
            }
          },
          parades: {
            label: () => 'Parade Websites',
            url: ({client}) => `/${client?.url}/parades`,
            children: {
              parade: {
                label: ({parade}) => `${parade?.name || ''}`,
                url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}`,
                children: {
                  paradeAttendance: {
                    label: () => 'Attendance & Analytics',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/attendance`
                  },
                  paradeConfirmationEmail: {
                    label: () => 'Confirmation Email',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/confirmation-email`
                  },
                  paradeDates: {
                    label: () => 'Dates / Times',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/dates`
                  },
                  paradeDownloadableFiles: {
                    label: () => 'Downloadable Files',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/files`
                  },
                  paradeHomePage: {
                    label: () => 'Home Page Generator',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/home-page`
                  },
                  paradeHomePageImages: {
                    label: () => 'Parade Home Page Image',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/home-page-images`
                  },
                  paradeName: {
                    label: () => 'Name & URL',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/name`
                  },
                  paradeOptionalFees: {
                    label: () => 'Optional Fees',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/fees`
                  },
                  paradePartnerPage: {
                    label: () => 'Partner Page Content',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/partner-page`
                  },
                  paradePromoCodes: {
                    label: () => 'Promotional Codes',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/promo-codes`
                  },
                  paradeProperties: {
                    label: () => 'Featured Home List',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/properties`
                  },
                  paradePropertyGroups: {
                    label: () => 'Optional Property Groups',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/property-groups`
                  },
                  paradePropertyTimeframes: {
                    label: () => 'Property Pricing & Availability',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/property-timeframes`
                  },
                  paradePublicVoting: {
                    label: () => 'Public Voting',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/public-voting`
                  },
                  paradeRegistrationList: {
                    label: () => 'Registration List',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/registration-list`
                  },
                  paradeSEO: {
                    label: () => 'Search Engine Optimization',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/seo`
                  },
                  paradeSponsorOptions: {
                    label: () => 'Sponsor Tiers & Options',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/sponsor-options`
                  },
                  paradeSponsorTimeframes: {
                    label: () => 'Sponsor Pricing & Availability',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/sponsor-timeframes`
                  },
                  paradeTickets: {
                    label: () => 'Tickets',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/tickets`
                  },
                  paradeTicketTakers: {
                    label: () => 'Ticket Takers',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/ticket-takers`
                  },
                  paradeTimeframes: {
                    label: () => 'Pricing & Availability',
                    url: ({client, parade}) => `/${client?.url}/parades/${parade?.url}/timeframes`
                  },
                }
              },
            }
          },
          surveys: {
            label: () => 'Surveys',
            url: ({client}) => `/${client?.url}/surveys`,
            children: {
              survey: {
                label: ({survey}) => `${survey?.name || ''}`,
                url: ({client, survey}) => `/${client?.url}/surveys/${survey?.url}`,
                children: {
                  surveyName: {
                    label: () => 'Name & URL',
                    url: ({client, survey}) => `/${client?.url}/surveys/${survey?.url}/name`
                  },
                  surveyQuestions: {
                    label: () => 'Survey Structure',
                    url: ({client, survey}) => `/${client?.url}/surveys/${survey?.url}/questions`
                  },
                  surveyResults: {
                    label: () => 'Survey Summary',
                    url: ({client, survey}) => `/${client?.url}/surveys/${survey?.url}/summary`
                  },
                  surveyTimeframe: {
                    label: () => 'Submission Timeframe',
                    url: ({client, survey}) => `/${client?.url}/surveys/${survey?.url}/timeframe`
                  },
                  surveyVoterList: {
                    label: () => 'Survey Submissions',
                    url: ({client, survey}) => `/${client?.url}/surveys/${survey?.url}/submissions`
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

export const createBreadcrumbMap = () => {
  const map = new Map();
  const initialKey = 'home';

  const traverseTree = ({ crumbs, key, obj }) => {
    const newCrumbs = [...crumbs, { label: obj.label, url: obj.url }];
    map.set(key, newCrumbs);

    for (const key in (obj.children || {})) {
      traverseTree({ crumbs: newCrumbs, key, obj: obj.children[key] });
    };
  };

  traverseTree({ crumbs: [], key: initialKey, obj: breadcrumbHierarchy[initialKey] });

  return map;
}

export const parseBreadcrumbMap = ({ key, map, params }) => {
  if (Object.values(params || []).some(val => !val)) {
    return null;
  }

  const crumbs = map.get(key);
  return crumbs.map(crumb => {
    return {
      label: crumb.label(params),
      url: crumb.url ? crumb.url(params) : null
    };
  });
};
