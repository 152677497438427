import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getParadeRequest,
  reorderParadeSponsorTiersRequest,
  updateParadeSponsorOptionsRequest
} from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import { fieldTypes, sponsorSizes, yesOrNo } from 'src/constants';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import ResourceList from 'src/components/ResourceList';
import AutoForm from 'src/components/AutoForm';

const ParadeSponsors = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const updatingSponsorSettings = useSelector(state => state.loadingStore.UPDATE_PARADE_SPONSOR_SETTINGS);
  const updatingSponsorTiers = useSelector(state => state.loadingStore.REORDER_PARADE_SPONSOR_TIERS);

  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  const groupActions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, parade },
          key: 'createParadeSponsorTier'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: sponsorTier => {
      dispatch(
        openModal({
          props: { client, parade, sponsorTier },
          key: 'editParadeSponsorTier'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: sponsorTier => {
      dispatch(
        openDialog({
          props: { client, parade, sponsorTier },
          key: 'removeParadeSponsorTier'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeSponsorOptions', client, parade }));
    dispatch(updateDrawerContent({
      key: 'paradeSponsorOptions',
      options: { client, parade } 
    }));
  }, [client, parade]);

  const reorderSponsorTierList = ({ list }) => {
    dispatch(reorderParadeSponsorTiersRequest({ clientURL: client.url, paradeURL: parade.url, form: { sponsorTiers: list } }));
  }

  const handleSettingsChange = (event) => {
    dispatch(updateParadeSponsorOptionsRequest({ clientURL: client.url, paradeURL: parade.url, form: event }));
  }

  const configuration = {
    formElements: {
      rows: [{
        forward: 'Tiers allow sponsors to be grouped into sections for more prominent display styles. Examples include \'Platinum\', \'Gold\', etc.'
      }, {
        elements: [{
          label: 'Sponsors Will Be Grouped Into Tiers',
          name: 'tieredSponsors',
          type: fieldTypes.select,
          options: yesOrNo,
          value: parade?.tieredSponsors || false
        }, {
          label: 'Default Sponsor Size',
          name: 'defaultSponsorSize',
          hide: { key: 'tieredSponsors', value: true },
          type: fieldTypes.select,
          options: sponsorSizes,
          value: parade?.defaultSponsorSize || 'medium'
        }]
      }]
    },
    submitCTA: 'Save'
  };
  
  return (
    <section className="sponsors grid">
      <section className="box">
        <div className="box-title">
          <h3>
            {
              paradeFetching ?
                <LoadingCircle variant="dark" /> :
                <span>Parade Sponsor Options
                {
                  updatingSponsorSettings && (
                    <LoadingCircle variant="dark" />
                  )
                }
                </span>
            }
          </h3>
        </div>
        {
          !paradeFetching && (
          <div className="box-content options">
            <AutoForm
              configuration={configuration}
              handleSubmit={handleSettingsChange}
              requestPrefix={'UPDATE_PARADE_SPONSOR_OPTIONS'}
            />
          </div>
          )
        }
      </section>
      { (!paradeFetching && parade?.tieredSponsors) &&
        <ResourceList
          actions={groupActions}
          draggable
          fetching={paradeFetching}
          handleDrag={reorderSponsorTierList}
          list={parade?.sponsorTiers}
          renderItem={sponsorTier =>
            <div className="sponsor-tier">{sponsorTier.name}</div>
          }
          renderKey="sponsorTierID"
          title="Sponsor Tiers"
          updating={updatingSponsorTiers}
        />
      }
    </section>
  )
}

export default ParadeSponsors;