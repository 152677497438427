import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  resetContestURLChanged,
  updateContestNameRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fieldTypes, inputTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ContestName = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const [configuration, setConfiguration] = useState(null);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  const handleSubmit = (event) => {
    dispatch(updateContestNameRequest({ clientURL: client.url, contestURL, form: event }));
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    if (contest?.urlChanged) {
      dispatch(resetContestURLChanged());
      navigate(`/${client.url}/contests/${contest.urlChanged}/name`);
    }
  }, [contest?.urlChanged]);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestName', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestName',
      options: { client, contest } 
    }));

    if (!configuration && contest) {
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Awards Program Name',
              name: 'name',
              type: fieldTypes.input,
              inputType: inputTypes.text,
              value: contest?.name,
              required: true
            }]
          }, {
            elements: [{
              label: 'Awards Program URL',
              name: 'url',
              type: fieldTypes.input,
              inputType: inputTypes.url,
              value: contest?.url,
              required: true
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [client, contest]);

  return (
    <section className="contest-name box">
      <div className="box-title">
        <h3>
          {
            contestFetching ?
              <LoadingCircle variant="dark" /> :
              <span>Name & URL</span>
          }
        </h3>
      </div>
      {
        (!contestFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_CONTEST_NAME'}
          />
          <hr />
          Public Landing Page: <Link
            target="_blank"
            to={`https://${client.url}.cpjam.com/${contestURL}`}
          >{`https://${client.url}.cpjam.com/${contestURL}`}</Link>
        </div>
        )
      }
    </section>
  )
}

export default ContestName;
