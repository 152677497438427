import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventRequest,
  resetEventURLChanged,
  updateEventNameRequest
} from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fieldTypes, inputTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const EventName = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const [configuration, setConfiguration] = useState(null);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  const handleSubmit = (event) => {
    dispatch(updateEventNameRequest({ clientURL: client.url, eventURL, form: event }));
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  useEffect(() => {
    if (event?.urlChanged) {
      dispatch(resetEventURLChanged());
      navigate(`/${client.url}/events/${event.urlChanged}/name`);
    }
  }, [event?.urlChanged]);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventName', client, event }));
    dispatch(updateDrawerContent({
      key: 'eventName',
      options: { client, event } 
    }));

    if (!configuration && event) {
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Event Name',
              name: 'name',
              type: fieldTypes.input,
              inputType: inputTypes.text,
              value: event?.name,
              required: true
            }]
          }, {
            elements: [{
              label: 'Event URL',
              name: 'url',
              type: fieldTypes.input,
              inputType: inputTypes.url,
              value: event?.url,
              required: true
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [client, event]);

  return (
    <section className="event-name box">
      <div className="box-title">
        <h3>
          {
            eventFetching ?
              <LoadingCircle variant="dark" /> :
              <span>Name & URL</span>
          }
        </h3>
      </div>
      {
        (!eventFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_EVENT_NAME'}
          />
          <hr />
          Public Landing Page: <Link
            target="_blank"
            to={`https://${client.url}.cpjam.com/${eventURL}`}
          >{`https://${client.url}.cpjam.com/${eventURL}`}</Link>
        </div>
        )
      }
    </section>
  )
}

export default EventName;
