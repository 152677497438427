import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventRequest } from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import { currency } from 'src/utilities/strings';

const EventPromoCodes = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, event },
          key: 'createEventPromoCode'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: promoCode => {
      dispatch(
        openModal({
          props: { client, event, promoCode },
          key: 'editEventPromoCode'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: promoCode => {
      dispatch(
        openDialog({
          props: { client, event, promoCode },
          key: 'removeEventPromoCode'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventPromoCodes', client, event }));
    dispatch(updateDrawerContent({
      key: 'eventPromoCodes',
      options: { client, event } 
    }));
  }, [client, event]);
  
  return (
    <ResourceList
      actions={actions}
      fetching={eventFetching}
      list={event?.promoCodes}
      renderHeaders={() => 
        <div className="promo-code">
          <div className="promo-code-key">Promotional Code Key</div>
          <div className="promo-code-type">Type</div>
          <div className="promo-code-amount">Amount/Percent</div>
        </div>
      }
      renderItem={promoCode => 
        <div className="promo-code">
          <div className="promo-code-key">{promoCode.promoKey}</div>
          <div className="promo-code-type">{promoCode.type === 'amount' ? 'Amount' : 'Percentage'}</div>
          <div className="promo-code-amount">{promoCode.type === 'amount' ? currency(promoCode.amount) : `${promoCode.amount}%`}</div>
        </div>
      }
      renderKey="promoCodeID"
      title="Promo Code List"
    />
  )
}

export default EventPromoCodes;