import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { getEventRequest } from 'src/redux/actions/events';
import { fieldTypes, inputTypes } from 'src/constants';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import AutoForm from 'src/components/AutoForm';
import { parseDateTime } from 'src/utilities/dates';

const EventPreview = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventPreview', client, event }));
    dispatch(updateDrawerContent({
      key: 'eventPreview',
      options: { client, event } 
    }));

  }, [client, event]);

  const handleRegistrationPreview = (event) => {
    const dateTime = encodeURIComponent(parseDateTime({ date: event.previewDate }));
    window.open(`https://${client.url}.cpjam.com/${eventURL}/preview/tickets/${dateTime}`, '_blank');
  }

  const registrationConfiguration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Registation Date/Time',
          name: 'previewDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: '',
          required: true
        }]
      }]
    },
    submitCTA: 'Preview'
  }

  return (
    <section className="box">
      <div className="box-title">
        <h3>
          {
            eventFetching ?
              <LoadingCircle variant="dark" /> :
              <span>Preview Registration Page</span>
          }
        </h3>
      </div>
      {
        !eventFetching && (
        <div className="box-content">
          <AutoForm
            configuration={registrationConfiguration}
            handleSubmit={handleRegistrationPreview}
            requestPrefix=""
          />
        </div>
        )
      }
    </section>
  )
}

export default EventPreview;
