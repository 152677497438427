import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUnauthorizedLogsRequest } from 'src/redux/actions/app';
import { openDialog } from 'src/redux/actions/dialog';
import ResourceList from 'src/components/ResourceList';

const UnauthorizedLogs = () => {
  const unauthorizedLogs = useSelector(state => state.appStore?.unauthorizedLogs);
  const logsFetching = useSelector(state => state.loadingStore.GET_UNAUTHORIZED_LOGS);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!logsFetching) {
      dispatch(getUnauthorizedLogsRequest());
    }
  }, []);

  const actions = [{
    color: 'danger',
    label: 'Clear All',
    variant: 'contained',
    fn: () => {
      dispatch(
        openDialog({
          key: 'clearUnauthorizedLogs'
        })
      )
    }
  }];
  
  return (
    <ResourceList
      actions={actions}
      fetching={logsFetching}
      list={unauthorizedLogs}
      notSelectable
      renderItem={log => 
        <div className="log">
          <div><span className="log-header">Date: </span><span className="log-date">{log.date}</span></div>
          <div><span className="log-header">Email: </span><span className="log-type">{log.email}</span></div>
          <div><span className="log-header">URL: </span><span className="log-type">{log.url}</span></div>
          <div><span className="log-header">IP Address: </span><span className="log-message">{log.ip}</span></div>
        </div>
      }
      renderKey="id"
      title="Unauthorized Access Logs"
    />
  )
}

export default UnauthorizedLogs;