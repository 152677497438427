export const ARCHIVE_EVENT_REQUEST = 'ARCHIVE_EVENT_REQUEST';
export const ARCHIVE_EVENT_SUCCESS = 'ARCHIVE_EVENT_SUCCESS';
export const ARCHIVE_EVENT_FAILED = 'ARCHIVE_EVENT_FAILED';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILED = 'CREATE_EVENT_FAILED';

export const DOWNLOAD_EVENT_REGISTRATIONS_REQUEST = 'DOWNLOAD_EVENT_REGISTRATIONS_REQUEST';
export const DOWNLOAD_EVENT_REGISTRATIONS_SUCCESS = 'DOWNLOAD_EVENT_REGISTRATIONS_SUCCESS';
export const DOWNLOAD_EVENT_REGISTRATIONS_FAILED = 'DOWNLOAD_EVENT_REGISTRATIONS_FAILED';

export const DOWNLOAD_EVENT_SUMMARY_REQUEST = 'DOWNLOAD_EVENT_SUMMARY_REQUEST';
export const DOWNLOAD_EVENT_SUMMARY_SUCCESS = 'DOWNLOAD_EVENT_SUMMARY_SUCCESS';
export const DOWNLOAD_EVENT_SUMMARY_FAILED = 'DOWNLOAD_EVENT_SUMMARY_FAILED';

export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILED = 'GET_EVENT_FAILED';

export const GET_EVENT_LIST_REQUEST = 'GET_EVENT_LIST_REQUEST';
export const GET_EVENT_LIST_SUCCESS = 'GET_EVENT_LIST_SUCCESS';
export const GET_EVENT_LIST_FAILED = 'GET_EVENT_LIST_FAILED';

export const REMOVE_EVENT_BANNER_IMAGE_REQUEST = 'REMOVE_EVENT_BANNER_IMAGE_REQUEST';
export const REMOVE_EVENT_BANNER_IMAGE_SUCCESS = 'REMOVE_EVENT_BANNER_IMAGE_SUCCESS';
export const REMOVE_EVENT_BANNER_IMAGE_FAILED = 'REMOVE_EVENT_BANNER_IMAGE_FAILED';

export const REMOVE_EVENT_COORDINATOR_REQUEST = 'REMOVE_EVENT_COORDINATOR_REQUEST';
export const REMOVE_EVENT_COORDINATOR_SUCCESS = 'REMOVE_EVENT_COORDINATOR_SUCCESS';
export const REMOVE_EVENT_COORDINATOR_FAILED = 'REMOVE_EVENT_COORDINATOR_FAILED';

export const REMOVE_EVENT_DOWNLOADABLE_FILE_REQUEST = 'REMOVE_EVENT_DOWNLOADABLE_FILE_REQUEST';
export const REMOVE_EVENT_DOWNLOADABLE_FILE_SUCCESS = 'REMOVE_EVENT_DOWNLOADABLE_FILE_SUCCESS';
export const REMOVE_EVENT_DOWNLOADABLE_FILE_FAILED = 'REMOVE_EVENT_DOWNLOADABLE_FILE_FAILED';

export const REMOVE_EVENT_OPTIONAL_FEE_REQUEST = 'REMOVE_EVENT_OPTIONAL_FEE_REQUEST';
export const REMOVE_EVENT_OPTIONAL_FEE_SUCCESS = 'REMOVE_EVENT_OPTIONAL_FEE_SUCCESS';
export const REMOVE_EVENT_OPTIONAL_FEE_FAILED = 'REMOVE_EVENT_OPTIONAL_FEE_FAILED';

export const REMOVE_EVENT_PROMO_CODE_REQUEST = 'REMOVE_EVENT_PROMO_CODE_REQUEST';
export const REMOVE_EVENT_PROMO_CODE_SUCCESS = 'REMOVE_EVENT_PROMO_CODE_SUCCESS';
export const REMOVE_EVENT_PROMO_CODE_FAILED = 'REMOVE_EVENT_PROMO_CODE_FAILED';

export const REMOVE_EVENT_REGISTRATION_REQUEST = 'REMOVE_EVENT_REGISTRATION_REQUEST';
export const REMOVE_EVENT_REGISTRATION_SUCCESS = 'REMOVE_EVENT_REGISTRATION_SUCCESS';
export const REMOVE_EVENT_REGISTRATION_FAILED = 'REMOVE_EVENT_REGISTRATION_FAILED';

export const REMOVE_EVENT_SLIDESHOW_IMAGE_REQUEST = 'REMOVE_EVENT_SLIDESHOW_IMAGE_REQUEST';
export const REMOVE_EVENT_SLIDESHOW_IMAGE_SUCCESS = 'REMOVE_EVENT_SLIDESHOW_IMAGE_SUCCESS';
export const REMOVE_EVENT_SLIDESHOW_IMAGE_FAILED = 'REMOVE_EVENT_SLIDESHOW_IMAGE_FAILED';

export const REMOVE_EVENT_SPONSOR_TIER_REQUEST = 'REMOVE_EVENT_SPONSOR_TIER_REQUEST';
export const REMOVE_EVENT_SPONSOR_TIER_SUCCESS = 'REMOVE_EVENT_SPONSOR_TIER_SUCCESS';
export const REMOVE_EVENT_SPONSOR_TIER_FAILED = 'REMOVE_EVENT_SPONSOR_TIER_FAILED';

export const REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST = 'REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST';
export const REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS = 'REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS';
export const REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_FAILED = 'REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_FAILED';

export const REMOVE_EVENT_TICKET_REQUEST = 'REMOVE_EVENT_TICKET_REQUEST';
export const REMOVE_EVENT_TICKET_SUCCESS = 'REMOVE_EVENT_TICKET_SUCCESS';
export const REMOVE_EVENT_TICKET_FAILED = 'REMOVE_EVENT_TICKET_FAILED';

export const REMOVE_EVENT_TIMEFRAME_REQUEST = 'REMOVE_EVENT_TIMEFRAME_REQUEST';
export const REMOVE_EVENT_TIMEFRAME_SUCCESS = 'REMOVE_EVENT_TIMEFRAME_SUCCESS';
export const REMOVE_EVENT_TIMEFRAME_FAILED = 'REMOVE_EVENT_TIMEFRAME_FAILED';

export const REORDER_EVENT_COORDINATORS_REQUEST = 'REORDER_EVENT_COORDINATORS_REQUEST';
export const REORDER_EVENT_COORDINATORS_SUCCESS = 'REORDER_EVENT_COORDINATORS_SUCCESS';
export const REORDER_EVENT_COORDINATORS_FAILED = 'REORDER_EVENT_COORDINATORS_FAILED';

export const REORDER_EVENT_OPTIONAL_FEES_REQUEST = 'REORDER_EVENT_OPTIONAL_FEES_REQUEST';
export const REORDER_EVENT_OPTIONAL_FEES_SUCCESS = 'REORDER_EVENT_OPTIONAL_FEES_SUCCESS';
export const REORDER_EVENT_OPTIONAL_FEES_FAILED = 'REORDER_EVENT_OPTIONAL_FEES_FAILED';

export const REORDER_EVENT_SLIDESHOW_IMAGES_REQUEST = 'REORDER_EVENT_SLIDESHOW_IMAGES_REQUEST';
export const REORDER_EVENT_SLIDESHOW_IMAGES_SUCCESS = 'REORDER_EVENT_SLIDESHOW_IMAGES_SUCCESS';
export const REORDER_EVENT_SLIDESHOW_IMAGES_FAILED = 'REORDER_EVENT_SLIDESHOW_IMAGES_FAILED';

export const REORDER_EVENT_SPONSOR_TIERS_REQUEST = 'REORDER_EVENT_SPONSOR_TIERS_REQUEST';
export const REORDER_EVENT_SPONSOR_TIERS_SUCCESS = 'REORDER_EVENT_SPONSOR_TIERS_SUCCESS';
export const REORDER_EVENT_SPONSOR_TIERS_FAILED = 'REORDER_EVENT_SPONSOR_TIERS_FAILED';

export const REORDER_EVENT_TICKETS_REQUEST = 'REORDER_EVENT_TICKETS_REQUEST';
export const REORDER_EVENT_TICKETS_SUCCESS = 'REORDER_EVENT_TICKETS_SUCCESS';
export const REORDER_EVENT_TICKETS_FAILED = 'REORDER_EVENT_TICKETS_FAILED';

export const RESET_EVENT_URL_CHANGED = 'RESET_EVENT_URL_CHANGED';
export const RESET_EVENT_URL_CHANGED_SUCCESS = 'RESET_EVENT_URL_CHANGED_SUCCESS';

export const SELECT_EVENT_BANNER_IMAGE_REQUEST = 'SELECT_EVENT_BANNER_IMAGE_REQUEST';
export const SELECT_EVENT_BANNER_IMAGE_SUCCESS = 'SELECT_EVENT_BANNER_IMAGE_SUCCESS';
export const SELECT_EVENT_BANNER_IMAGE_FAILED = 'SELECT_EVENT_BANNER_IMAGE_FAILED';

export const SEND_EVENT_NOTIFICATIONS_REQUEST = 'SEND_EVENT_NOTIFICATIONS_REQUEST';
export const SEND_EVENT_NOTIFICATIONS_SUCCESS = 'SEND_EVENT_NOTIFICATIONS_SUCCESS';
export const SEND_EVENT_NOTIFICATIONS_FAILED = 'SEND_EVENT_NOTIFICATIONS_FAILED';

export const UPDATE_EVENT_CONFIGURATION_REQUEST = 'UPDATE_EVENT_CONFIGURATION_REQUEST';
export const UPDATE_EVENT_CONFIGURATION_SUCCESS = 'UPDATE_EVENT_CONFIGURATION_SUCCESS';
export const UPDATE_EVENT_CONFIGURATION_FAILED = 'UPDATE_EVENT_CONFIGURATION_FAILED';

export const UPDATE_EVENT_CONFIRMATION_EMAIL_REQUEST = 'UPDATE_EVENT_CONFIRMATION_EMAIL_REQUEST';
export const UPDATE_EVENT_CONFIRMATION_EMAIL_SUCCESS = 'UPDATE_EVENT_CONFIRMATION_EMAIL_SUCCESS';
export const UPDATE_EVENT_CONFIRMATION_EMAIL_FAILED = 'UPDATE_EVENT_CONFIRMATION_EMAIL_FAILED';

export const UPDATE_EVENT_CONNECTED_CONTEST_REQUEST = 'UPDATE_EVENT_CONNECTED_CONTEST_REQUEST';
export const UPDATE_EVENT_CONNECTED_CONTEST_SUCCESS = 'UPDATE_EVENT_CONNECTED_CONTEST_SUCCESS';
export const UPDATE_EVENT_CONNECTED_CONTEST_FAILED = 'UPDATE_EVENT_CONNECTED_CONTEST_FAILED';

export const UPDATE_EVENT_COORDINATOR_REQUEST = 'UPDATE_EVENT_COORDINATOR_REQUEST';
export const UPDATE_EVENT_COORDINATOR_SUCCESS = 'UPDATE_EVENT_COORDINATOR_SUCCESS';
export const UPDATE_EVENT_COORDINATOR_FAILED = 'UPDATE_EVENT_COORDINATOR_FAILED';

export const UPDATE_EVENT_DETAILS_REQUEST = 'UPDATE_EVENT_DETAILS_REQUEST';
export const UPDATE_EVENT_DETAILS_SUCCESS = 'UPDATE_EVENT_DETAILS_SUCCESS';
export const UPDATE_EVENT_DETAILS_FAILED = 'UPDATE_EVENT_DETAILS_FAILED';

export const UPDATE_EVENT_LOCATION_REQUEST = 'UPDATE_EVENT_LOCATION_REQUEST';
export const UPDATE_EVENT_LOCATION_SUCCESS = 'UPDATE_EVENT_LOCATION_SUCCESS';
export const UPDATE_EVENT_LOCATION_FAILED = 'UPDATE_EVENT_LOCATION_FAILED';

export const UPDATE_EVENT_NAME_REQUEST = 'UPDATE_EVENT_NAME_REQUEST';
export const UPDATE_EVENT_NAME_SUCCESS = 'UPDATE_EVENT_NAME_SUCCESS';
export const UPDATE_EVENT_NAME_FAILED = 'UPDATE_EVENT_NAME_FAILED';

export const UPDATE_EVENT_OPTIONAL_FEE_REQUEST = 'UPDATE_EVENT_OPTIONAL_FEE_REQUEST';
export const UPDATE_EVENT_OPTIONAL_FEE_SUCCESS = 'UPDATE_EVENT_OPTIONAL_FEE_SUCCESS';
export const UPDATE_EVENT_OPTIONAL_FEE_FAILED = 'UPDATE_EVENT_OPTIONAL_FEE_FAILED';

export const UPDATE_EVENT_PARTNER_PAGE_REQUEST = 'UPDATE_EVENT_PARTNER_PAGE_REQUEST';
export const UPDATE_EVENT_PARTNER_PAGE_SUCCESS = 'UPDATE_EVENT_PARTNER_PAGE_SUCCESS';
export const UPDATE_EVENT_PARTNER_PAGE_FAILED = 'UPDATE_EVENT_PARTNER_PAGE_FAILED';

export const UPDATE_EVENT_PROMO_CODE_REQUEST = 'UPDATE_EVENT_PROMO_CODE_REQUEST';
export const UPDATE_EVENT_PROMO_CODE_SUCCESS = 'UPDATE_EVENT_PROMO_CODE_SUCCESS';
export const UPDATE_EVENT_PROMO_CODE_FAILED = 'UPDATE_EVENT_PROMO_CODE_FAILED';

export const UPDATE_EVENT_REGISTRATION_REQUEST = 'UPDATE_EVENT_REGISTRATION_REQUEST';
export const UPDATE_EVENT_REGISTRATION_SUCCESS = 'UPDATE_EVENT_REGISTRATION_SUCCESS';
export const UPDATE_EVENT_REGISTRATION_FAILED = 'UPDATE_EVENT_REGISTRATION_FAILED';

export const UPDATE_EVENT_REGISTRATION_QUESTIONS_REQUEST = 'UPDATE_EVENT_REGISTRATION_QUESTIONS_REQUEST';
export const UPDATE_EVENT_REGISTRATION_QUESTIONS_SUCCESS = 'UPDATE_EVENT_REGISTRATION_QUESTIONS_SUCCESS';
export const UPDATE_EVENT_REGISTRATION_QUESTIONS_FAILED = 'UPDATE_EVENT_REGISTRATION_QUESTIONS_FAILED';

export const UPDATE_EVENT_SEO_REQUEST = 'UPDATE_EVENT_SEO_REQUEST';
export const UPDATE_EVENT_SEO_SUCCESS = 'UPDATE_EVENT_SEO_SUCCESS';
export const UPDATE_EVENT_SEO_FAILED = 'UPDATE_EVENT_SEO_FAILED';

export const UPDATE_EVENT_SPONSOR_TIER_REQUEST = 'UPDATE_EVENT_SPONSOR_TIER_REQUEST';
export const UPDATE_EVENT_SPONSOR_TIER_SUCCESS = 'UPDATE_EVENT_SPONSOR_TIER_SUCCESS';
export const UPDATE_EVENT_SPONSOR_TIER_FAILED = 'UPDATE_EVENT_SPONSOR_TIER_FAILED';

export const UPDATE_EVENT_SPONSOR_OPTIONS_REQUEST = 'UPDATE_EVENT_SPONSOR_OPTIONS_REQUEST';
export const UPDATE_EVENT_SPONSOR_OPTIONS_SUCCESS = 'UPDATE_EVENT_SPONSOR_OPTIONS_SUCCESS';
export const UPDATE_EVENT_SPONSOR_OPTIONS_FAILED = 'UPDATE_EVENT_SPONSOR_OPTIONS_FAILED';

export const UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST = 'UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST';
export const UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS = 'UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS';
export const UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_FAILED = 'UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_FAILED';

export const UPDATE_EVENT_TICKET_REQUEST = 'UPDATE_EVENT_TICKET_REQUEST';
export const UPDATE_EVENT_TICKET_SUCCESS = 'UPDATE_EVENT_TICKET_SUCCESS';
export const UPDATE_EVENT_TICKET_FAILED = 'UPDATE_EVENT_TICKET_FAILED';

export const UPDATE_EVENT_TIMEFRAME_REQUEST = 'UPDATE_EVENT_TIMEFRAME_REQUEST';
export const UPDATE_EVENT_TIMEFRAME_SUCCESS = 'UPDATE_EVENT_TIMEFRAME_SUCCESS';
export const UPDATE_EVENT_TIMEFRAME_FAILED = 'UPDATE_EVENT_TIMEFRAME_FAILED';

export const UPLOAD_EVENT_BANNER_IMAGE_REQUEST = 'UPLOAD_EVENT_BANNER_IMAGE_REQUEST';
export const UPLOAD_EVENT_BANNER_IMAGE_SUCCESS = 'UPLOAD_EVENT_BANNER_IMAGE_SUCCESS';
export const UPLOAD_EVENT_BANNER_IMAGE_FAILED = 'UPLOAD_EVENT_BANNER_IMAGE_FAILED';

export const UPLOAD_EVENT_DOWNLOADABLE_FILE_REQUEST = 'UPLOAD_EVENT_DOWNLOADABLE_FILE_REQUEST';
export const UPLOAD_EVENT_DOWNLOADABLE_FILE_SUCCESS = 'UPLOAD_EVENT_DOWNLOADABLE_FILE_SUCCESS';
export const UPLOAD_EVENT_DOWNLOADABLE_FILE_FAILED = 'UPLOAD_EVENT_DOWNLOADABLE_FILE_FAILED';

export const UPLOAD_EVENT_SLIDESHOW_IMAGE_REQUEST = 'UPLOAD_EVENT_SLIDESHOW_IMAGE_REQUEST';
export const UPLOAD_EVENT_SLIDESHOW_IMAGE_SUCCESS = 'UPLOAD_EVENT_SLIDESHOW_IMAGE_SUCCESS';
export const UPLOAD_EVENT_SLIDESHOW_IMAGE_FAILED = 'UPLOAD_EVENT_SLIDESHOW_IMAGE_FAILED';

export function archiveEventRequest (body) {
  return {
    body,
    type: ARCHIVE_EVENT_REQUEST
  }
}

export function createEventRequest (body) {
  return {
    body,
    type: CREATE_EVENT_REQUEST
  }
}

export function downloadEventRegistrationsRequest (body) {
  return {
    body,
    type: DOWNLOAD_EVENT_REGISTRATIONS_REQUEST
  }
}


export function downloadEventSummaryRequest (body) {
  return {
    body,
    type: DOWNLOAD_EVENT_SUMMARY_REQUEST
  }
}

export function getEventRequest ({ clientURL, eventURL }) {
  return {
    clientURL,
    eventURL,
    type: GET_EVENT_REQUEST
  }
}

export function getEventListRequest ({ clientURL }) {
  return {
    clientURL,
    type: GET_EVENT_LIST_REQUEST
  }
}

export function removeEventBannerImageRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_BANNER_IMAGE_REQUEST
  }
}

export function removeEventCoordinatorRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_COORDINATOR_REQUEST
  }
}

export function removeEventDownloadableFileRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_DOWNLOADABLE_FILE_REQUEST
  }
}

export function removeEventOptionalFeeRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_OPTIONAL_FEE_REQUEST
  }
}

export function removeEventPromoCodeRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_PROMO_CODE_REQUEST
  }
}

export function removeEventRegistrationRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_REGISTRATION_REQUEST
  }
}

export function removeEventSlideshowImageRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_SLIDESHOW_IMAGE_REQUEST
  }
}

export function removeEventSponsorTierRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_SPONSOR_TIER_REQUEST
  }
}

export function removeEventSupportingProductTimeframeRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST
  }
}

export function removeEventTicketRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_TICKET_REQUEST
  }
}

export function removeEventTimeframeRequest (body) {
  return {
    body,
    type: REMOVE_EVENT_TIMEFRAME_REQUEST
  }
}

export function reorderEventCoordinatorsRequest (body) {
  return {
    body,
    type: REORDER_EVENT_COORDINATORS_REQUEST
  }
}

export function reorderEventFeesRequest (body) {
  return {
    body,
    type: REORDER_EVENT_OPTIONAL_FEES_REQUEST
  }
}

export function reorderEventSlideshowImagesRequest (body) {
  return {
    body,
    type: REORDER_EVENT_SLIDESHOW_IMAGES_REQUEST
  }
}

export function reorderEventSponsorTiersRequest (body) {
  return {
    body,
    type: REORDER_EVENT_SPONSOR_TIERS_REQUEST
  }
}

export function reorderEventTicketsRequest (body) {
  return {
    body,
    type: REORDER_EVENT_TICKETS_REQUEST
  }
}

export function resetEventURLChanged () {
  return {
    type: RESET_EVENT_URL_CHANGED
  }
}

export function selectEventBannerImageRequest (body) {
  return {
    body,
    type: SELECT_EVENT_BANNER_IMAGE_REQUEST
  }
}

export function sendEventNotificationsRequest (body) {
  return {
    body,
    type: SEND_EVENT_NOTIFICATIONS_REQUEST
  }
}

export function updateEventConfigurationRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_CONFIGURATION_REQUEST
  }
}

export function updateEventConfirmationEmailRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_CONFIRMATION_EMAIL_REQUEST
  }
}

export function updateEventConnectedContestRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_CONNECTED_CONTEST_REQUEST
  }
}

export function updateEventCoordinatorRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_COORDINATOR_REQUEST
  }
}

export function updateEventDetailsRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_DETAILS_REQUEST
  }
}

export function updateEventLocationRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_LOCATION_REQUEST
  }
}

export function updateEventNameRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_NAME_REQUEST
  }
}

export function updateEventOptionalFeeRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_OPTIONAL_FEE_REQUEST
  }
}

export function updateEventPartnerPageRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_PARTNER_PAGE_REQUEST
  }
}

export function updateEventPromoCodeRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_PROMO_CODE_REQUEST
  }
}

export function updateEventRegistrationRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_REGISTRATION_REQUEST
  }
}

export function updateEventRegistrationQuestionsRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_REGISTRATION_QUESTIONS_REQUEST
  }
}

export function updateEventSEORequest (body) {
  return {
    body,
    type: UPDATE_EVENT_SEO_REQUEST
  }
}

export function updateEventSponsorTierRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_SPONSOR_TIER_REQUEST
  }
}

export function updateEventSponsorOptionsRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_SPONSOR_OPTIONS_REQUEST
  }
}

export function updateEventSupportingProductTimeframeRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST
  }
}

export function updateEventTicketRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_TICKET_REQUEST
  }
}

export function updateEventTimeframeRequest (body) {
  return {
    body,
    type: UPDATE_EVENT_TIMEFRAME_REQUEST
  }
}

export function uploadEventBannerImageRequest (body) {
  return {
    body,
    type: UPLOAD_EVENT_BANNER_IMAGE_REQUEST
  }
}

export function uploadEventDownloadableFileRequest (body) {
  return {
    body,
    type: UPLOAD_EVENT_DOWNLOADABLE_FILE_REQUEST
  }
}

export function uploadEventSlideshowImageRequest (body) {
  return {
    body,
    type: UPLOAD_EVENT_SLIDESHOW_IMAGE_REQUEST
  }
}