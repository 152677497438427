import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getContestRequest,
  updateContestCustomPageSettingsRequest,
  updateContestCustomPageTextRequest
} from 'src/redux/actions/contests';
import TinyMCE from 'src/components/TinyMCE';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import { fieldTypes, inputTypes } from 'src/constants';

const ContestCustomPage = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const [configuration, setConfiguration] = useState(null);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const restricted = [
    'archives',
    'category-list',
    'checkin',
    'entries',
    'fullscreen',
    'gallery',
    'preview',
    'properties',
    'receipts',
    'registration',
    'scholarships',
    'scoring',
    'sponsors',
    'thank-you',
    'tickets',
    'vote'
  ];

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestCustomPage', client, contest }));
    dispatch(updateDrawerContent({
      key: 'contestCustomPage',
      options: { client, contest } 
    }));

    if (!configuration && contest) {
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Title',
              name: 'title',
              type: fieldTypes.input,
              inputType: inputTypes.text,
              value: contest?.customPage?.title || ''
            }]
          }, {
            elements: [{
              label: 'URL',
              name: 'url',
              type: fieldTypes.input,
              inputType: inputTypes.url,
              restricted,
              value: contest?.customPage?.url || ''
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [client, contest]);

  const handlePageTextSubmit = (event) => {
    dispatch(updateContestCustomPageTextRequest({ clientURL: client.url, contestURL: contest.url, form: { ...contest.customPage, pageText: event }}));
  }

  const handleSettingsSubmit = (event) => {
    dispatch(updateContestCustomPageSettingsRequest({ clientURL: client.url, contestURL: contest.url, form: { ...contest.customPage, ...event }}));
  }

  return (
    contest &&
      <section className="contest-custom-page grid">
        <section className="box">
          <div className="box-title">
            <h3>
              {
                contestFetching ?
                  <LoadingCircle variant="dark" /> :
                  <span>Custom Page</span>
              }
            </h3>
          </div>
          <div className="box-content">
            <AutoForm
              configuration={configuration}
              handleSubmit={handleSettingsSubmit}
              requestPrefix={'UPDATE_CONTEST_CUSTOM_PAGE_SETTINGS'}
            />
          </div>
        </section>
        
        { contest?.customPage &&
          <TinyMCE
            handleSubmit={(event => handlePageTextSubmit(event))}
            requestPrefix="UPDATE_CONTEST_CUSTOM_PAGE_TEXT"
            title="Custom Page Content"
            value={contest?.customPage?.pageText}
          />
        }
      </section>
  )
}

export default ContestCustomPage;
