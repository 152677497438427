import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSurveyRequest,
  resetSurveyURLChanged,
  updateSurveyNameRequest
} from 'src/redux/actions/surveys';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fieldTypes, inputTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const SurveyName = () => {
  const client = useSelector(state => state.clientStore?.client);
  const survey = useSelector(state => state.surveyStore?.survey);
  const surveyFetching = useSelector(state => state.loadingStore.GET_SURVEY);
  const [configuration, setConfiguration] = useState(null);
  const { surveyURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!surveyURL) {
    navigate('/');
  }

  const handleSubmit = (survey) => {
    dispatch(updateSurveyNameRequest({ clientURL: client.url, surveyURL, form: survey }));
  }

  useEffect(() => {
    if (!survey) {
      dispatch(getSurveyRequest({ clientURL: client.url, surveyURL }));
    }
  }, []);

  useEffect(() => {
    if (survey?.urlChanged) {
      dispatch(resetSurveyURLChanged());
      navigate(`/${client.url}/surveys/${survey.urlChanged}/name`);
    }
  }, [survey?.urlChanged]);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'surveyName', client, survey }));
    dispatch(updateDrawerContent({
      key: 'surveyName',
      options: { client, survey } 
    }));

    if (!configuration && survey) {
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Survey Name',
              name: 'name',
              type: fieldTypes.input,
              inputType: inputTypes.text,
              value: survey?.name,
              required: true
            }]
          }, {
            elements: [{
              label: 'Survey URL',
              name: 'url',
              type: fieldTypes.input,
              inputType: inputTypes.domain,
              value: survey?.url,
              required: true
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [client, survey]);

  return (
    <section className="survey-name box">
      <div className="box-title">
        <h3>
          {
            surveyFetching ?
              <LoadingCircle variant="dark" /> :
              <span>Name & URL</span>
          }
        </h3>
      </div>
      {
        (!surveyFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_SURVEY_NAME'}
          />
          <hr />
          Public Landing Page: <Link
            target="_blank"
            to={`https://${client.url}.cpjam.com/${surveyURL}`}
          >{`https://${client.url}.cpjam.com/${surveyURL}`}</Link>
        </div>
        )
      }
    </section>
  )
}

export default SurveyName;
