import { call, put, takeEvery } from 'redux-saga/effects';
import handleFetch from 'src/utilities/fetch';
import * as actions from 'src/redux/actions/app';
import * as userActions from 'src/redux/actions/users';
import { toast } from 'react-toastify';

async function changeEmail(form) {
  return await handleFetch({ url: '/users/email/by-admin', form });
}

async function changePassword(form) {
  return await handleFetch({ url: '/users/password/by-admin', form });
}

async function clearErrorLogs() {
  return await handleFetch({ url: '/error-logs/clear', form: {} });
}

async function clearLoginLogs() {
  return await handleFetch({ url: '/login-logs/clear', form: {} });
}

async function clearUnauthorizedLogs() {
  return await handleFetch({ url: '/unauthorized-logs/clear', form: {} });
}

async function getCurrentResources() {
  return await handleFetch({ url: '/current-resources' });
}

async function getErrorLogs() {
  return await handleFetch({ url: '/error-logs' });
}

async function getLoginLogs() {
  return await handleFetch({ url: '/login-logs' });
}

async function getUnauthorizedLogs() {
  return await handleFetch({ url: '/unauthorized-logs' });
}

async function getInvoices() {
  return await handleFetch({ url: '/invoices' });
}

async function getPasswordRequests() {
  return await handleFetch({ url: '/password-requests' });
}

async function getSystemNotifications() {
  return await handleFetch({ url: '/system-notifications' });
}

async function migrateUser({ newUserID, oldUserID }) {
  return await handleFetch({ url: `/users/migrate/from/${oldUserID}/to/${newUserID}`, form: {} });
}

async function removeInvoice({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/invoices/remove`, form });
}

async function removeInvoiceLineItem({ itemID }) {
  return await handleFetch({ url: `/invoice-line-items/${itemID}/remove` });
}

async function removeSystemNotification({ form }) {
  return await handleFetch({ url: '/system-notifications/remove', form });
}

async function sendNotifications({ form }) {
  return await handleFetch({ url: '/notify-administrators', form });
}

async function updateInvoice({ clientURL, form }) {
  return await handleFetch({ url: `/clients/${clientURL}/invoices`, form });
}

async function updateSystemNotification({ form }) {
  return await handleFetch({ url: '/system-notifications', form });
}

function* changeEmailRequest(request) {
  try {
    const context = yield call(() => changeEmail(request.body));
    yield put({ type: actions.CHANGE_EMAIL_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CHANGE_EMAIL_FAILED, error });
  }
}

function* changePasswordRequest(request) {
  try {
    const context = yield call(() => changePassword(request.body));
    yield put({ type: actions.CHANGE_PASSWORD_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CHANGE_PASSWORD_FAILED, error });
  }
}

function* clearErrorLogsRequest(request) {
  try {
    const context = yield call(() => clearErrorLogs(request.body));
    yield put({ type: actions.CLEAR_ERROR_LOGS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CLEAR_ERROR_LOGS_FAILED, error });
  }
}

function* clearLoginLogsRequest(request) {
  try {
    const context = yield call(() => clearLoginLogs(request.body));
    yield put({ type: actions.CLEAR_LOGIN_LOGS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CLEAR_LOGIN_LOGS_FAILED, error });
  }
}

function* clearUnauthorizedLogsRequest(request) {
  try {
    const context = yield call(() => clearUnauthorizedLogs(request.body));
    yield put({ type: actions.CLEAR_UNAUTHORIZED_LOGS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CLEAR_UNAUTHORIZED_LOGS_FAILED, error });
  }
}

function* getCurrentResourcesRequest(request) {
  try {
    const context = yield call(() => getCurrentResources(request.body));
    yield put({ type: actions.GET_CURRENT_RESOURCES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_CURRENT_RESOURCES_FAILED, error });
  }
}

function* getErrorLogsRequest(request) {
  try {
    const context = yield call(() => getErrorLogs(request.body));
    yield put({ type: actions.GET_ERROR_LOGS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_ERROR_LOGS_FAILED, error });
  }
}

function* getLoginLogsRequest(request) {
  try {
    const context = yield call(() => getLoginLogs(request.body));
    yield put({ type: actions.GET_LOGIN_LOGS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_LOGIN_LOGS_FAILED, error });
  }
}

function* getUnauthorizedLogsRequest(request) {
  try {
    const context = yield call(() => getUnauthorizedLogs(request.body));
    yield put({ type: actions.GET_UNAUTHORIZED_LOGS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_UNAUTHORIZED_LOGS_FAILED, error });
  }
}

function* getInvoicesRequest(request) {
  try {
    const context = yield call(() => getInvoices(request.body));
    yield put({ type: actions.GET_INVOICES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_INVOICES_FAILED, error });
  }
}

function* getPasswordRequestsRequest(request) {
  try {
    const context = yield call(() => getPasswordRequests(request.body));
    yield put({ type: actions.GET_PASSWORD_REQUESTS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_PASSWORD_REQUESTS_FAILED, error });
  }
}

function* getSystemNotificationsRequest(request) {
  try {
    const context = yield call(() => getSystemNotifications(request.body));
    yield put({ type: actions.GET_SYSTEM_NOTIFICATIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_SYSTEM_NOTIFICATIONS_FAILED, error });
  }
}

function* migrateUserRequest(request) {
  try {
    const context = yield call(() => migrateUser(request.body));
    yield put({ type: actions.MIGRATE_USER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.MIGRATE_USER_FAILED, error });
  }
}

function* removeInvoiceRequest(request) {
  try {
    const context = yield call(() => removeInvoice(request.body));
    yield put({ type: actions.REMOVE_INVOICE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_INVOICE_FAILED, error });
  }
}

function* removeInvoiceLineItemRequest(request) {
  try {
    const context = yield call(() => removeInvoiceLineItem(request.body));
    yield put({ type: actions.REMOVE_INVOICE_LINE_ITEM_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_INVOICE_LINE_ITEM_FAILED, error });
  }
}

function* removeSystemNotificationRequest(request) {
  try {
    const context = yield call(() => removeSystemNotification(request.body));
    yield put({ type: actions.REMOVE_SYSTEM_NOTIFICATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_SYSTEM_NOTIFICATION_FAILED, error });
  }
}

function* sendNotificationsRequest(request) {
  try {
    const context = yield call(() => sendNotifications(request.body));
    yield put({ type: actions.SEND_NOTIFICATIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.SEND_NOTIFICATIONS_FAILED, error });
  }
}

function* updateInvoiceRequest(request) {
  try {
    const context = yield call(() => updateInvoice(request.body));
    yield put({ type: actions.UPDATE_INVOICE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_INVOICE_FAILED, error });
  }
}

function* updateSystemNotificationRequest(request) {
  try {
    const context = yield call(() => updateSystemNotification(request.body));
    yield put({ type: actions.UPDATE_SYSTEM_NOTIFICATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_SYSTEM_NOTIFICATION_FAILED, error });
  }
}

function* apiFailed(request) {
  toast.error(request.error.message);
  if (request.error.status === 403) {
    yield put({ type: userActions.CLEAR_USER });
  }
}

function* appSaga() {
  yield takeEvery(actions.CHANGE_EMAIL_REQUEST, changeEmailRequest);
  yield takeEvery(actions.CHANGE_PASSWORD_REQUEST, changePasswordRequest);
  yield takeEvery(actions.CLEAR_ERROR_LOGS_REQUEST, clearErrorLogsRequest);
  yield takeEvery(actions.CLEAR_LOGIN_LOGS_REQUEST, clearLoginLogsRequest);
  yield takeEvery(actions.CLEAR_UNAUTHORIZED_LOGS_REQUEST, clearUnauthorizedLogsRequest);
  yield takeEvery(actions.GET_CURRENT_RESOURCES_REQUEST, getCurrentResourcesRequest);
  yield takeEvery(actions.GET_ERROR_LOGS_REQUEST, getErrorLogsRequest);
  yield takeEvery(actions.GET_LOGIN_LOGS_REQUEST, getLoginLogsRequest);
  yield takeEvery(actions.GET_UNAUTHORIZED_LOGS_REQUEST, getUnauthorizedLogsRequest);
  yield takeEvery(actions.GET_INVOICES_REQUEST, getInvoicesRequest);
  yield takeEvery(actions.GET_PASSWORD_REQUESTS_REQUEST, getPasswordRequestsRequest);
  yield takeEvery(actions.GET_SYSTEM_NOTIFICATIONS_REQUEST, getSystemNotificationsRequest);
  yield takeEvery(actions.MIGRATE_USER_REQUEST, migrateUserRequest);
  yield takeEvery(actions.REMOVE_INVOICE_REQUEST, removeInvoiceRequest);
  yield takeEvery(actions.REMOVE_INVOICE_LINE_ITEM_REQUEST, removeInvoiceLineItemRequest);
  yield takeEvery(actions.REMOVE_SYSTEM_NOTIFICATION_REQUEST, removeSystemNotificationRequest);
  yield takeEvery(actions.SEND_NOTIFICATIONS_REQUEST, sendNotificationsRequest);
  yield takeEvery(actions.UPDATE_INVOICE_REQUEST, updateInvoiceRequest);
  yield takeEvery(actions.UPDATE_SYSTEM_NOTIFICATION_REQUEST, updateSystemNotificationRequest);
  
  yield takeEvery(actions.CHANGE_EMAIL_FAILED, apiFailed);
  yield takeEvery(actions.CHANGE_PASSWORD_FAILED, apiFailed);
  yield takeEvery(actions.CLEAR_ERROR_LOGS_FAILED, apiFailed);
  yield takeEvery(actions.CLEAR_LOGIN_LOGS_FAILED, apiFailed);
  yield takeEvery(actions.CLEAR_UNAUTHORIZED_LOGS_FAILED, apiFailed);
  yield takeEvery(actions.GET_CURRENT_RESOURCES_FAILED, apiFailed);
  yield takeEvery(actions.GET_ERROR_LOGS_FAILED, apiFailed);
  yield takeEvery(actions.GET_LOGIN_LOGS_FAILED, apiFailed);
  yield takeEvery(actions.GET_UNAUTHORIZED_LOGS_FAILED, apiFailed);
  yield takeEvery(actions.GET_INVOICES_FAILED, apiFailed);
  yield takeEvery(actions.GET_PASSWORD_REQUESTS_FAILED, apiFailed);
  yield takeEvery(actions.GET_SYSTEM_NOTIFICATIONS_FAILED, apiFailed);
  yield takeEvery(actions.MIGRATE_USER_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_INVOICE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_INVOICE_LINE_ITEM_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_SYSTEM_NOTIFICATION_FAILED, apiFailed);
  yield takeEvery(actions.SEND_NOTIFICATIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_INVOICE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_SYSTEM_NOTIFICATION_FAILED, apiFailed);

  yield takeEvery(actions.REMOVE_INVOICE_SUCCESS, getInvoicesRequest);
  yield takeEvery(actions.UPDATE_INVOICE_SUCCESS, getInvoicesRequest);
}

export default appSaga;
